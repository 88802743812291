import AboutUs from "./components/pages/AboutUs";
import Faq from "./components/pages/Manufacturing";

import HomePage from "./components/pages/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactUs from "./components/pages/ContactUs";
import { useRef } from "react";
import Verticals from "./components/pages/Verticals";
import Aerospace from "./components/pages/Aerospace";
import Agriculture from "./components/pages/Agriculture";
import BusinessFinance from "./components/pages/Business-Finance";
import Engineering from "./components/pages/Engineering";
import HumanServices from "./components/pages/Human-services";
import Transportation from "./components/pages/Transportation";
import Architecture from "./components/pages/Architecture-construction";
import ComputerScience from "./components/pages/Computer-science";
import HealthScience from "./components/pages/Health-science";
import LawPublicSafety from "./components/pages/Law-public-safety";
import Arts from "./components/pages/Arts";
import Education from "./components/pages/Education";
import Hospitality from "./components/pages/Hospitality";
import Version from "./components/pages/Version";
import AskBetsyAI from "./components/pages/AskBetsyAI";
import "bootstrap/dist/css/bootstrap.min.css";
import UtahAtGlance from "./components/pages/UtahAtGlance";
import Footer from "./components/pages/Footer";
import NewNavbar from "./components/pages/NewNavbar";
import { useLocation } from "react-router-dom";
import UtahAtGlance1 from "./components/pages/UathAtGlance1";
import DataSet from "./components/pages/DataSet";
import ArcGISMap from "./components/pages/ArcGISMap";
import FourtySevenGMap from "./components/pages/FourtySevenGMap";
import GoogleMapWithUtah from "./components/pages/CountyMap";
import GenderPopulationPage from "./components/pages/PopulationDemography";
import UtahCities from "./components/pages/UtahCities";
import UtahUniversities from "./components/pages/UtahUniversities";
import UnemploymentRate from "./components/pages/UnemploymentRate";
import UtahJobs from "./components/pages/UtahJobs";
import UtahIndustries from "./components/pages/UtahIndustries";
import UtahMap from "./components/pages/UtahMap";
import UtahOlympic from "./components/pages/UtahOlympic";
import SchoolDistrict from "./components/pages/SchoolDistrict";
import School1 from "./components/pages/Schools/School1";
import School2 from "./components/pages/Schools/School2";
import School3 from "./components/pages/Schools/School3";
import School4 from "./components/pages/Schools/School4";
import School5 from "./components/pages/Schools/School5";
import School6 from "./components/pages/Schools/School6";
import School7 from "./components/pages/Schools/School7";
import School8 from "./components/pages/Schools/School8";
import School9 from "./components/pages/Schools/School9";
import School10 from "./components/pages/Schools/School10";
import School11 from "./components/pages/Schools/School11";
import School12 from "./components/pages/Schools/School12";
import School13 from "./components/pages/Schools/School13";
import School14 from "./components/pages/Schools/School14";
import School15 from "./components/pages/Schools/School15";
import School16 from "./components/pages/Schools/School16";
import School17 from "./components/pages/Schools/School17";
import School18 from "./components/pages/Schools/School18";
import School19 from "./components/pages/Schools/School19";
import School20 from "./components/pages/Schools/School20";
import School21 from "./components/pages/Schools/School21";
import School22 from "./components/pages/Schools/School22";
import School23 from "./components/pages/Schools/School23";
import School24 from "./components/pages/Schools/School24";
import School25 from "./components/pages/Schools/School25";
import School26 from "./components/pages/Schools/School26";
import School27 from "./components/pages/Schools/School27";
import School28 from "./components/pages/Schools/School28";
import School29 from "./components/pages/Schools/School29";
import School30 from "./components/pages/Schools/School30";
import School31 from "./components/pages/Schools/School31";
import School32 from "./components/pages/Schools/School32";
import School33 from "./components/pages/Schools/School33";
import School34 from "./components/pages/Schools/School34";
import School35 from "./components/pages/Schools/School35";
import School36 from "./components/pages/Schools/School36";
import School37 from "./components/pages/Schools/School37";
import School38 from "./components/pages/Schools/School38";
import School39 from "./components/pages/Schools/School39";
import School40 from "./components/pages/Schools/School40";
import School41 from "./components/pages/Schools/School41";
import ScrollToTop from "./components/pages/ScrollToTop";
import CredentialsSignInPage from "./components/pages/Login";
import ContactUs1 from "./components/pages/ContactUs1";
import Torch404 from "./components/pages/Torch404";
import { useMatch } from "react-router-dom";
import GeothermalMap from "./components/pages/GeoThermal";
import NationalPark from "./components/pages/NationalPark";
import StateParks from "./components/pages/StateParks";
import NationalPark1 from "./components/pages/NationalPark/NationalPark1";
import NationalPark2 from "./components/pages/NationalPark/NationalPark2";
import NationalPark3 from "./components/pages/NationalPark/NationalPark3";
import NationalPark4 from "./components/pages/NationalPark/NationalPark4";
import NationalPark5 from "./components/pages/NationalPark/NationalPark5";
import { Login } from "@mui/icons-material";
import Login1 from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import { Helmet, HelmetProvider } from "react-helmet-async";
import UtahGoverner from "./components/pages/UtahGoverner";
import Airforce from "./components/pages/Airforce";

// New component to handle routes and location-dependent logic
function AppContent({ swiperRef }) {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isTorch404 = useMatch("*");
  return (
    <div className="min-h-screen flex flex-col">
      {/* <Navbar1 swiperRef={swiperRef} /> */}
      <ScrollToTop />
      {isHomePage ? null : <NewNavbar />}
      <main className="flex-1 bg-[#d2eaff]">
        {/* <main className={`flex-1 ${!isTorch404 ? "bg-[#d2eaff]" : ""}`}> */}
        <HelmetProvider>
          <Routes>
            <Route path="/map" element={<UtahMap />} />

            <Route
              path="/utah-at-glance/nationalparks"
              element={<NationalPark />}
            />
            <Route
              path="/utah-at-glance/nationalparks/arches"
              element={<NationalPark1 />}
            />
            <Route
              path="/utah-at-glance/nationalparks/bryce-canyon"
              element={<NationalPark2 />}
            />
            <Route
              path="/utah-at-glance/nationalparks/canyonland"
              element={<NationalPark3 />}
            />
            <Route
              path="/utah-at-glance/nationalparks/capitolreef"
              element={<NationalPark4 />}
            />
            <Route
              path="/utah-at-glance/nationalparks/zion"
              element={<NationalPark5 />}
            />
            <Route path="/utah-at-glance/stateparks" element={<StateParks />} />

            {/* <Route path="/login" element={<CredentialsSignInPage />} /> */}
            <Route path="/login" element={<Login1 />} />
            <Route path="/login/dashboard" element={<Dashboard />} />
            <Route
              path="/slc-ut2034-count-down"
              element={
                <>
                  <Helmet>
                    <title>SLC 2034 Countdown | UtahData</title>
                    <meta
                      name="description"
                      content="Join the countdown to 2034 in Salt Lake City! Explore data, trends, and key milestones shaping the city's future growth and development at UtahData.org."
                    />
                  </Helmet>
                  <UtahOlympic />
                </>
              }
            />
            <Route
              path="/utah-at-glance"
              element={
                <>
                  <Helmet>
                    <title>Utah at a Glance | UtahData</title>
                    <meta
                      name="description"
                      content="Discover Utah at a glance with key insights on places to visit, Jobs, top universities, Schools in Utah, and Total Population."
                    />
                  </Helmet>
                  <UtahAtGlance />
                </>
              }
            />
            <Route
              path="/utah-at-glance/counties"
              element={<GoogleMapWithUtah />}
            />
            <Route
              path="/utah-at-glance/gender-population"
              element={<GenderPopulationPage />}
            />
            <Route
              path="/utah-at-glance/utah-cities"
              element={<UtahCities />}
            />
            <Route
              path="/utah-at-glance/utah-jobs"
              element={
                <>
                  <Helmet>
                    <title>Utah jobs</title>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-glance/utah-jobs"
                    />
                  </Helmet>
                  <UtahJobs />
                </>
              }
            />
            <Route
              path="/utah-at-glance/utah-industries"
              element={<UtahIndustries />}
            />
            <Route
              path="/utah-at-glance/utah-universities"
              element={<UtahUniversities />}
            />
            <Route
              path="/utah-at-glance/unemployment-rate"
              element={<UnemploymentRate />}
            />
            {/* <Route path="/data1" element={<UtahAtGlance1 />} /> */}
            <Route
              path="/utah-at-glance/schools"
              element={
                <>
                  <Helmet>
                    <title>Utah at a Glance - Schools</title>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-glance/schools"
                    />
                  </Helmet>
                  <SchoolDistrict />
                </>
              }
            />
            <Route
              path="/utah-at-glance/geothermal-hotspot"
              element={<GeothermalMap />}
            />
            <Route
              path="/utah-at-glance/hill-airforce"
              element={<Airforce />}
            />
            <Route
              path="/utah-at-glance/schools/alpine"
              element={<School1 />}
            />
            <Route
              path="/utah-at-glance/schools/beaver"
              element={<School2 />}
            />
            <Route
              path="/utah-at-glance/schools/boxelder"
              element={<School3 />}
            />
            <Route path="/utah-at-glance/schools/cache" element={<School4 />} />
            <Route
              path="/utah-at-glance/schools/canyons"
              element={<School5 />}
            />
            <Route
              path="/utah-at-glance/schools/carbon"
              element={<School6 />}
            />
            <Route
              path="/utah-at-glance/schools/daggett"
              element={<School7 />}
            />
            <Route path="/utah-at-glance/schools/davis" element={<School8 />} />
            <Route
              path="/utah-at-glance/schools/duchesne"
              element={<School9 />}
            />
            <Route
              path="/utah-at-glance/schools/emery"
              element={<School10 />}
            />
            <Route
              path="/utah-at-glance/schools/garfield"
              element={<School11 />}
            />
            <Route
              path="/utah-at-glance/schools/grand"
              element={<School12 />}
            />
            <Route
              path="/utah-at-glance/schools/granite"
              element={<School13 />}
            />
            <Route path="/utah-at-glance/schools/iron" element={<School14 />} />
            <Route
              path="/utah-at-glance/schools/jordan"
              element={<School15 />}
            />
            <Route path="/utah-at-glance/schools/juab" element={<School16 />} />
            <Route path="/utah-at-glance/schools/kane" element={<School17 />} />
            <Route
              path="/utah-at-glance/schools/logan"
              element={<School18 />}
            />
            <Route
              path="/utah-at-glance/schools/millard"
              element={<School19 />}
            />
            <Route
              path="/utah-at-glance/schools/morgan"
              element={<School20 />}
            />
            <Route
              path="/utah-at-glance/schools/murray"
              element={<School21 />}
            />
            <Route path="/utah-at-glance/schools/nebo" element={<School22 />} />
            <Route
              path="/utah-at-glance/schools/sanpete"
              element={<School23 />}
            />
            <Route
              path="/utah-at-glance/schools/summit"
              element={<School24 />}
            />
            <Route
              path="/utah-at-glance/schools/ogden"
              element={<School25 />}
            />
            <Route path="/utah-at-glance/schools/park" element={<School26 />} />
            <Route
              path="/utah-at-glance/schools/piute"
              element={<School27 />}
            />
            <Route
              path="/utah-at-glance/schools/provo"
              element={<School28 />}
            />
            <Route path="/utah-at-glance/schools/rich" element={<School29 />} />
            <Route path="/utah-at-glance/schools/salt" element={<School30 />} />
            <Route path="/utah-at-glance/schools/san" element={<School31 />} />
            <Route
              path="/utah-at-glance/schools/sevier"
              element={<School32 />}
            />
            <Route
              path="/utah-at-glance/schools/southSanpete"
              element={<School33 />}
            />
            <Route
              path="/utah-at-glance/schools/southSummit"
              element={<School34 />}
            />
            <Route
              path="/utah-at-glance/schools/tintic"
              element={<School35 />}
            />
            <Route
              path="/utah-at-glance/schools/tooele"
              element={<School36 />}
            />
            <Route
              path="/utah-at-glance/schools/uintah"
              element={<School37 />}
            />
            <Route
              path="/utah-at-glance/schools/wasatch"
              element={<School38 />}
            />
            <Route
              path="/utah-at-glance/schools/washington"
              element={<School39 />}
            />
            <Route
              path="/utah-at-glance/schools/wayne"
              element={<School40 />}
            />
            <Route
              path="/utah-at-glance/schools/weber"
              element={<School41 />}
            />

            <Route
              path="/utah-at-glance/unemployment-rate/dataset"
              element={<DataSet />}
            />
            {/* <Route path="/flipbook" element={<FlipBook pdfFile={samplePdf}/>} /> */}
            {/* <Route path="/flipbook" element={<FlipBook3D pdfFile={pdfFile}/>}/> */}

            <Route
              path="/utah-at-glance/unemployment-rate/dataset"
              element={<DataSet />}
            />
            {/* <Route path="/flipbook" element={<FlipBook pdfFile={samplePdf}/>} /> */}
            {/* <Route path="/flipbook" element={<FlipBook3D pdfFile={pdfFile}/>}/> */}

            <Route
              path="/verticals"
              element={
                <>
                  <Helmet>
                    <title>Verticals | UtahData</title>
                    <meta
                      name="description"
                      content="Explore UtahData.org verticals for in-depth, interactive Utah data insights on Utah's economy, demographics, education, and more—empowering informed decisions."
                    />
                  </Helmet>
                  <Verticals />
                </>
              }
            />
            <Route
              path="/about"
              element={
                <>
                  <Helmet>
                    <title>About | UtahData</title>
                    <meta
                      name="description"
                      content="UtahData.org empowers Utahns with interactive, user-friendly data insights that highlight our state's rich diversity and dynamic growth."
                    />
                  </Helmet>
                  <AboutUs />
                </>
              }
            />
            <Route path="/verticals/manufacturing" element={<Faq />} />
            {/* <Route path="/contact-us" element={<ContactUs />} /> */}
            <Route
              path="/contact-us"
              element={
                <>
                  <Helmet>
                    <title>Contact Us | UtahData</title>
                    <meta
                      name="description"
                      content="Contact UtahData.org for inquiries, support, and feedback. We're here to help you access valuable data insights and hear your thoughts on Utah’s dynamic growth."
                    />
                  </Helmet>
                  <ContactUs1 />
                </>
              }
            />
            <Route path="/" element={<HomePage swiperRef={swiperRef} />} />
            <Route
              path="/verticals/aerospace-and-defense"
              element={
                <>
                  <Helmet>
                    <title>Utah Aerospace and defence</title>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/aerospace-and-defense"
                    />
                  </Helmet>
                  <Aerospace />
                </>
              }
            />
            <Route path="/verticals/food-industry" element={<Agriculture />} />
            <Route
              path="/verticals/business-finance-marketing"
              element={<BusinessFinance />}
            />
            <Route
              path="/verticals/engineering-and-technology"
              element={<Engineering />}
            />
            <Route
              path="/verticals/human-services"
              element={<HumanServices />}
            />
            <Route
              path="/verticals/transportation-distribution-logistics"
              element={<Transportation />}
            />
            <Route
              path="/verticals/architecture-and-construction"
              element={<Architecture />}
            />
            <Route
              path="/verticals/computer-science-and-it"
              element={<ComputerScience />}
            />
            <Route
              path="/verticals/health-science"
              element={<HealthScience />}
            />
            <Route
              path="/verticals/law-public-safety-corrections-security"
              element={<LawPublicSafety />}
            />
            <Route
              path="/verticals/arts-audio-visual-technology-and-communication"
              element={<Arts />}
            />
            <Route
              path="/verticals/education-and-training"
              element={<Education />}
            />
            <Route
              path="/verticals/hospitality-and-tourism"
              element={<Hospitality />}
            />
            <Route path="/version" element={<Version />} />
            <Route
              path="/betsy"
              element={
                <>
                  <Helmet>
                    <title>Betsy | UtahData</title>
                    <meta
                      name="description"
                      content="Meet Betsy at UtahData.org—your guide to Utah’s data insights. Explore trends in demographics, economy, and more with user-friendly, interactive analytics."
                    />
                  </Helmet>
                  <AskBetsyAI />
                </>
              }
            />
            <Route path="/bdx-arcgis-example-utah" element={<ArcGISMap />} />
            <Route path="/47G" element={<FourtySevenGMap />} />
            <Route path="/governer" element={<UtahGoverner />} />

            <Route path="*" element={<Torch404 />} />
          </Routes>
        </HelmetProvider>
      </main>
      <Footer />
    </div>
  );
}

function App() {
  const swiperRef = useRef(null);

  return (
    <Router basename="/">
      {" "}
      {/* Move Router here to wrap the whole app */}
      <AppContent swiperRef={swiperRef} />
    </Router>
  );
}

export default App;
