// // import React, { useRef } from "react";
// // import Table from "react-bootstrap/Table";
// // import { Bar } from "react-chartjs-2";
// // import "../styles/pdf.css";
// // import {
// //   Chart as ChartJS,
// //   CategoryScale,
// //   LinearScale,
// //   BarElement,
// //   Title,
// //   Tooltip,
// //   Legend,
// // } from "chart.js";
// // import html2canvas from "html2canvas";
// // import jsPDF from "jspdf";
// // const utahData = "/img/1.png";
// // const kpi = "/img/KPI-DASH 2.png";
// // const bodex = "/img/bodexLogo.png";
// // const pdf = "/img/pdf1.png";
// // ChartJS.register(
// //   CategoryScale,
// //   LinearScale,
// //   BarElement,
// //   Title,
// //   Tooltip,
// //   Legend
// // );

// // const AeroTable2 = ({ apiData }) => {
// //   const pdfRef = useRef();

// //   if (apiData.length === 0) {
// //     return <p>No data available for employment by sex.</p>;
// //   }

// //   // Extract years and employment counts
// //   const years = Object.keys(apiData[0]).filter((key) => /^\d{4}$/.test(key));
// //   const maleData = apiData.find((item) => item.sex_label_value === "Male");
// //   const femaleData = apiData.find((item) => item.sex_label_value === "Female");

// //   const chartData = {
// //     labels: years,
// //     datasets: [
// //       {
// //         label: "Male Employment Count",
// //         data: maleData ? years.map((year) => maleData[year]) : [],
// //         backgroundColor: "rgba(54, 162, 235, 0.7)",
// //         borderColor: "rgba(54, 162, 235, 1)",
// //         borderWidth: 1,
// //       },
// //       {
// //         label: "Female Employment Count",
// //         data: femaleData ? years.map((year) => femaleData[year]) : [],
// //         backgroundColor: "rgba(255, 99, 132, 0.7)",
// //         borderColor: "rgba(255, 99, 132, 1)",
// //         borderWidth: 1,
// //       },
// //     ],
// //   };

// //   const downloadPDF = () => {
// //     const input = pdfRef.current;
// //     const originalWidth = input.style.width;
// //     input.style.width = "1200px";

// //     html2canvas(input, { scale: 3 }).then((canvas) => {
// //       const imgData = canvas.toDataURL("image/png");
// //       const pdf = new jsPDF("p", "mm", "a4");

// //       const imgWidth = 210;
// //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

// //       pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
// //       pdf.setFontSize(26);
// //       pdf.text("UTahData.org", 95, 22);
// //       pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

// //       pdf.setFontSize(12);
// //       pdf.setLineWidth(0.5);
// //       pdf.setDrawColor(0, 0, 0);
// //       pdf.line(10, 35, 200, 35);

// //       const headerHeight = 38;
// //       pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

// //       pdf.line(10, 260, 200, 260);

// //       const footerStartY = 265;
// //       pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);

// //       pdf.setFontSize(10);
// //       pdf.setTextColor(0, 122, 255);
// //       pdf.text("Built by", 55, footerStartY + 10);
// //       pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
// //       pdf.text("using", 112, footerStartY + 10);
// //       pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
// //       pdf.text("20250106.v1.85", 148, footerStartY + 10);

// //       const currentDate = new Date();
// //       const formattedDate = currentDate.toLocaleDateString();
// //       pdf.setFontSize(12);
// //       pdf.text(`Downloaded on: ${formattedDate}`, 150, 295); // Bottom right corner date

// //       const currentURL = window.location.href;
// //       pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

// //       pdf.save("dashboard_with_footer.pdf");
// //       input.style.width = originalWidth;
// //     });
// //   };

// //   return (
// //     <div className="max-w-full mx-auto p-4">
// //       <div ref={pdfRef} className=" content-to-export">
// //         <div style={{ padding: "20px" }}>
// //           <h2>Employment Data by Sex (Yearly)</h2>

// //               <div style={{ overflowX: "auto", marginBottom: "20px" }}>
// //             <table border="1" cellPadding="10" style={{ width: "100%", minWidth: "600px" }}>
// //               <thead>
// //                 <tr>
// //                   <th>Year</th>
// //                   <th>Sex Label</th>
// //                   <th>Employment Count</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {apiData.map((row, index) =>
// //                   years.map((year) => (
// //                     <tr key={`${index}-${year}`}>
// //                       <td>{year}</td>
// //                       <td>{row.sex_label_value}</td>
// //                       <td>{row[year]}</td>
// //                     </tr>
// //                   ))
// //                 )}
// //               </tbody>
// //             </table>
// //           </div>

// //           {/* Chart Section with Horizontal Scrolling */}
// //           <div
// //         style={{
// //     overflowX: "auto", // Enables horizontal scroll
// //     display: "flex",
// //     justifyContent: "center",
// //     width: "100%", // Takes full width
// //   }}
// // >
// //   <div
// //     style={{
// //       minWidth: "1000px", // Minimum width to enable scroll
// //       width: "1000px",   // Chart width, ensuring horizontal scroll on smaller screens
// //       height: "400px",
// //     }}
// //   >
// //               <Bar data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
// //             </div>
// //           </div>
// //         </div>
// //       </div>

// //       {/* Centered Button */}
// //       <div className="flex justify-center">
// //         <button onClick={downloadPDF} className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between">
// //           <span>Download as PDF </span><span><img className="w-[2rem]" src={pdf} /></span>
// //         </button>
// //       </div>
// //     </div>
// //   );
// // };

// // export default AeroTable2;

// import React, { useRef } from "react";
// import Table from "react-bootstrap/Table";
// import { Bar } from "react-chartjs-2";
// import "../styles/pdf.css";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// const utahData = "/img/1.png";
// const kpi = "/img/KPI-DASH 2.png";
// const bodex = "/img/bodexLogo.png";
// const pdf = "/img/pdf1.png";
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const AeroTable2 = ({ apiData }) => {
//   const pdfRef = useRef();

//   if (apiData.length === 0) {
//     return <p>No data available for employment by sex.</p>;
//   }

//   // Extract years and employment counts
//   const years = Object.keys(apiData[0]).filter((key) => /^\d{4}$/.test(key));
//   const maleData = apiData.find((item) => item.sex_label === "Male");
//   const femaleData = apiData.find((item) => item.sex_label === "Female");

//   const chartData = {
//     labels: years,
//     datasets: [
//       {
//         label: "Male Employment Count",
//         data: maleData ? years.map((year) => maleData[year]) : [],
//         backgroundColor: "rgba(54, 162, 235, 0.7)",
//         borderColor: "rgba(54, 162, 235, 1)",
//         borderWidth: 1,
//       },
//       {
//         label: "Female Employment Count",
//         data: femaleData ? years.map((year) => femaleData[year]) : [],
//         backgroundColor: "rgba(255, 99, 132, 0.7)",
//         borderColor: "rgba(255, 99, 132, 1)",
//         borderWidth: 1,
//       },
//     ],
//   };

//   const downloadPDF = () => {
//     const input = pdfRef.current;
//     const originalWidth = input.style.width;
//     input.style.width = "1200px";

//     html2canvas(input, { scale: 3 }).then((canvas) => {
//       const imgData = canvas.toDataURL("image/png");
//       const pdf = new jsPDF("p", "mm", "a4");

//       const imgWidth = 210;
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;

//       pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
//       pdf.setFontSize(26);
//       pdf.text("UTahData.org", 95, 22);
//       pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

//       pdf.setFontSize(12);
//       pdf.setLineWidth(0.5);
//       pdf.setDrawColor(0, 0, 0);
//       pdf.line(10, 35, 200, 35);

//       const headerHeight = 38;
//       pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

//       pdf.line(10, 260, 200, 260);

//       const footerStartY = 265;
//       pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);

//       pdf.setFontSize(10);
//       pdf.setTextColor(0, 122, 255);
//       pdf.text("Built by", 55, footerStartY + 10);
//       pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
//       pdf.text("using", 112, footerStartY + 10);
//       pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
//       pdf.text("20250106.v1.85", 148, footerStartY + 10);

//       const currentDate = new Date();
//       const formattedDate = currentDate.toLocaleDateString();
//       pdf.setFontSize(12);
//       pdf.text(`Downloaded on: ${formattedDate}`, 150, 295); // Bottom right corner date

//       const currentURL = window.location.href;
//       pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

//       pdf.save("dashboard_with_footer.pdf");
//       input.style.width = originalWidth;
//     });
//   };

//   return (
//     <div className="max-w-full mx-auto p-4">
//       <div ref={pdfRef} className=" content-to-export">
//         <div style={{ padding: "20px" }}>
//           <h2>Employment Data by Sex (Yearly)</h2>

//           {/* <div style={{ overflowX: "auto", marginBottom: "20px" }}>
//             <table border="1" className="mx-auto" cellPadding="10" style={{ width: "100%", minWidth: "600px" }}> */}

//             <div className="overflow-x-auto">
//             <Table striped bordered hover responsive className="mb-4">
//               <thead>
//                 <tr>
//                   <th>Year</th>
//                   <th>Sex Label</th>
//                   <th>Employment Count</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {apiData.map((row, index) =>
//                   years.map((year) => (
//                     <tr key={`${index}-${year}`}>
//                       <td>{year}</td>
//                       <td>{row.sex_label_value}</td>
//                       <td>{row[year]}</td>
//                     </tr>
//                   ))
//                 )}
//               </tbody>
//             </Table>
//           </div>

//           {/* Chart Section with Horizontal Scrolling */}
//           <div
//             style={{
//               overflowX: "auto",
//               display: "flex",
//               justifyContent: "center",
//               width: "100%",
//             }}
//           >
//             <div
//               style={{
//                 minWidth: "1000px",
//                 width: "1000px",
//                 height: "400px",
//               }}
//             >
//               <Bar data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Centered Button */}
//       <div className="flex justify-center">
//         <button onClick={downloadPDF} className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between">
//           <span>Download as PDF </span><span><img className="w-[2rem]" src={pdf} /></span>
//         </button>
//       </div>
//     </div>
//   );
// };
// export default AeroTable2;

import React, { useRef } from "react";
import Table from "react-bootstrap/Table";
import { Line } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// Import images for PDF header and footer
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

const AeroTable2 = ({ apiData }) => {
  const pdfRef = useRef();

  // Extract years and data preparation
  const years = ["2018", "2019", "2020", "2021", "2022", "2023"];
  const chartData = {
    labels: years,
    datasets: apiData.map((item, index) => ({
      label: item.sex_label,
      data: years.map((year) => item[year]),
      borderColor: `hsl(${(index * 90) % 360}, 70%, 50%)`,
      backgroundColor: `hsla(${(index * 90) % 360}, 70%, 50%, 0.3)`,
      tension: 0.4,
      fill: true,
    })),
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    input.style.width = "1100px";

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.line(10, 35, 200, 35);

      // Main Content
      pdf.addImage(imgData, "PNG", 5, 38, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260);
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
      pdf.text("20250106.v1.85", 148, 275);
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("employment-by-sex.pdf");
      input.style.width = "";
    });
  };

  return (
    <div className="container">
      <div ref={pdfRef} className="content-to-export">
        <h3 className="mt-3">Employment by Sex (2018-2023)</h3>

        {/* Table Section */}
        <Table striped bordered hover responsive className="mb-4">
          <thead>
            <tr>
              <th>Sex</th>
              {years.map((year) => (
                <th key={year}>{year}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {apiData.map((item, index) => (
              <tr key={index}>
                <td>{item.sex_label}</td>
                {years.map((year) => (
                  <td key={year}>{item[year]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Chart Section */}
        {/* <div
          style={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              minWidth: "600px",
              width: "1000px",
              height: "400px",
            }}
          > */}
        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
              height: "400px",
            }}
          >
            <Line
              data={chartData}
              options={{ responsive: true, maintainAspectRatio: false }}
            />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center mt-4">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
          <span>
            <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AeroTable2;
