// // import React from 'react';
// // import 'bootstrap/dist/css/bootstrap.min.css';
// // import { Table } from 'react-bootstrap';
// // import { Bar } from 'react-chartjs-2';
// // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // const TableNo15 = () => {
// //   // Data for the table and chart
// //   const ethnicityData = [
// //     { ethnicity: 'Hispanic or Latino', share2018: 18.96, share2022: 20.00, share2023: 20.52 },
// //     { ethnicity: 'Not Hispanic or Latino', share2018: 81.04, share2022: 80.00, share2023: 79.48 },
// //   ];

// //   // Chart data
// //   const chartData = {
// //     labels: ethnicityData.map(item => item.ethnicity),
// //     datasets: [
// //       {
// //         label: 'Share % (2018)',
// //         data: ethnicityData.map(item => item.share2018),
// //         backgroundColor: 'rgba(75, 192, 192, 0.6)',
// //         borderColor: 'rgba(75, 192, 192, 1)',
// //         borderWidth: 1,
// //       },
// //       {
// //         label: 'Share % (2022)',
// //         data: ethnicityData.map(item => item.share2022),
// //         backgroundColor: 'rgba(255, 159, 64, 0.6)',
// //         borderColor: 'rgba(255, 159, 64, 1)',
// //         borderWidth: 1,
// //       },
// //       {
// //         label: 'Share % (2023)',
// //         data: ethnicityData.map(item => item.share2023),
// //         backgroundColor: 'rgba(255, 99, 132, 0.6)',
// //         borderColor: 'rgba(255, 99, 132, 1)',
// //         borderWidth: 1,
// //       },
// //     ],
// //   };

// //   // Bar chart options
// //   const options = {
// //     responsive: true,
// //     scales: {
// //       y: {
// //         beginAtZero: true,
// //         max: 100, // Maximum percentage value
// //         ticks: {
// //           callback: (value) => value + '%', // Append % sign to ticks
// //         },
// //       },
// //     },
// //     plugins: {
// //       legend: {
// //         position: 'top',
// //       },
// //     },
// //   };

// //   return (
// //     <div className="container">
// //       <h2>Ethnicity Share Comparison (2018, 2022, 2023)</h2>

// //       {/* Responsive Table */}
// //       <Table striped bordered hover responsive>
// //         <thead>
// //           <tr>
// //             <th>Ethnicity</th>
// //             <th>Share % (2018)</th>
// //             <th>Share % (2022)</th>
// //             <th>Share % (2023)</th>
// //           </tr>
// //         </thead>
// //         <tbody>
// //           {ethnicityData.map((item, index) => (
// //             <tr key={index}>
// //               <td>{item.ethnicity}</td>
// //               <td>{item.share2018.toFixed(2)}%</td>
// //               <td>{item.share2022.toFixed(2)}%</td>
// //               <td>{item.share2023.toFixed(2)}%</td>
// //             </tr>
// //           ))}
// //         </tbody>
// //       </Table>

// //       {/* Bar Chart */}
// //       <div style={{ height: '400px', width: '100%' }}>
// //         <Bar data={chartData} options={options} />
// //       </div>
// //     </div>
// //   );
// // };

// // export default TableNo15;

// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const TableNo15 = () => {
//   // Data for the table and chart
//   const ethnicityData = [
//     { ethnicity: 'Hispanic or Latino', share2018: 18.96, share2022: 20.00, share2023: 20.52 },
//     { ethnicity: 'Not Hispanic or Latino', share2018: 81.04, share2022: 80.00, share2023: 79.48 },
//   ];

//   // Chart data
//   const chartData = {
//     labels: ethnicityData.map(item => item.ethnicity),
//     datasets: [
//       {
//         label: 'Share % (2018)',
//         data: ethnicityData.map(item => item.share2018),
//         backgroundColor: 'rgba(75, 192, 192, 0.6)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Share % (2022)',
//         data: ethnicityData.map(item => item.share2022),
//         backgroundColor: 'rgba(255, 159, 64, 0.6)',
//         borderColor: 'rgba(255, 159, 64, 1)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Share % (2023)',
//         data: ethnicityData.map(item => item.share2023),
//         backgroundColor: 'rgba(255, 99, 132, 0.6)',
//         borderColor: 'rgba(255, 99, 132, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Bar chart options
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       y: {
//         beginAtZero: true,
//         max: 100,
//         ticks: {
//           callback: (value) => value + '%', // Append % sign to Y-axis ticks
//           color: '#4B5563',
//           font: {
//             size: 12,
//           },
//         },
//         title: {
//           display: true,
//           text: 'Percentage Share',
//           color: '#4B5563',
//           font: {
//             size: 14,
//           },
//         },
//       },
//       x: {
//         ticks: {
//           color: '#4B5563',
//           font: {
//             size: 12,
//           },
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         position: 'top',
//         labels: {
//           font: {
//             size: 12,
//           },
//         },
//       },
//       tooltip: {
//         callbacks: {
//           label: (context) => `${context.dataset.label}: ${context.raw.toFixed(2)}%`, // Show value with % in tooltip
//         },
//         bodyFont: {
//           size: 12,
//         },
//       },
//     },
//   };

//   return (
//     <div className="container my-5">
//       <h2 className="text-center mb-4">Ethnicity Share Comparison (2018, 2022, 2023)</h2>

//       {/* Responsive Table */}
//       <Table striped bordered hover responsive className="mb-4">
//         <thead>
//           <tr>
//             <th>Ethnicity</th>
//             <th>Share % (2018)</th>
//             <th>Share % (2022)</th>
//             <th>Share % (2023)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {ethnicityData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.ethnicity}</td>
//               <td>{item.share2018.toFixed(2)}%</td>
//               <td>{item.share2022.toFixed(2)}%</td>
//               <td>{item.share2023.toFixed(2)}%</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Bar Chart with Horizontal Scroll */}
//       <div className="overflow-auto">
//         <div style={{ minWidth: '500px', height: '400px' }}>
//           <Bar data={chartData} options={options} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TableNo15;

import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TableNo15 = () => {
  const pdfRef = useRef();

  const ethnicityData = [
    {
      ethnicity: "Hispanic or Latino",
      share2018: 18.96,
      share2022: 20.0,
      share2023: 20.52,
    },
    {
      ethnicity: "Not Hispanic or Latino",
      share2018: 81.04,
      share2022: 80.0,
      share2023: 79.48,
    },
  ];

  const chartData = {
    labels: ethnicityData.map((item) => item.ethnicity),
    datasets: [
      {
        label: "Share % (2018)",
        data: ethnicityData.map((item) => item.share2018),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Share % (2022)",
        data: ethnicityData.map((item) => item.share2022),
        backgroundColor: "rgba(255, 159, 64, 0.6)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 1,
      },
      {
        label: "Share % (2023)",
        data: ethnicityData.map((item) => item.share2023),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: (value) => value + "%",
          color: "#4B5563",
          font: { size: 12 },
        },
        title: {
          display: true,
          text: "Percentage Share",
          color: "#4B5563",
          font: { size: 14 },
        },
      },
      x: {
        ticks: {
          color: "#4B5563",
          font: { size: 12 },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: { font: { size: 12 } },
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.dataset.label}: ${context.raw.toFixed(2)}%`,
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1200px";

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);

      pdf.setFontSize(12);
      pdf.line(10, 35, 200, 35);

      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

      pdf.line(10, 260, 200, 260);
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
      pdf.text("20250106.v1.85", 148, 275);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      pdf.save("ethnicity-share.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="container">
      <h2 className="text-center mb-4">
        Ethnicity Share Comparison (2018, 2022, 2023)
      </h2>
      <div ref={pdfRef}>
        <Table striped bordered hover responsive className="mb-4">
          <thead>
            <tr>
              <th>Ethnicity</th>
              <th>Share % (2018)</th>
              <th>Share % (2022)</th>
              <th>Share % (2023)</th>
            </tr>
          </thead>
          <tbody>
            {ethnicityData.map((item, index) => (
              <tr key={index}>
                <td>{item.ethnicity}</td>
                <td>{item.share2018.toFixed(2)}%</td>
                <td>{item.share2022.toFixed(2)}%</td>
                <td>{item.share2023.toFixed(2)}%</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* <div
          style={{
            overflowX: "auto", // Enables horizontal scroll
            display: "flex",
            justifyContent: "center",
            width: "100%", // Takes full width
          }}
        >
          <div
            style={{
              minWidth: "600px", // Minimum width to enable scroll
              width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
              height: "400px",
            }}
          > */}

        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
              height: "400px",
            }}
          >
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
        </button>
      </div>
    </div>
  );
};

export default TableNo15;
