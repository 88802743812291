// // import React from "react";
// // import { Bar } from "react-chartjs-2";
// // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

// // // Register Chart.js components
// // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // const GenderPopulationPage = () => {
// //   // Example population data
// //   const populationData = {
// //     male: 1.75, // in millions
// //     female: 1.7, // in millions
// //   };

// //   // Chart data
// //   const data = {
// //     labels: ["Male", "Female"],
// //     datasets: [
// //       {
// //         label: "Population (in millions)",
// //         data: [populationData.male, populationData.female],
// //         backgroundColor: ["#4F46E5", "#EC4899"], // Blue and Pink
// //         borderWidth: 1,
// //       },
// //     ],
// //   };

// //   // Chart options
// //   const options = {
// //     responsive: true,
// //     plugins: {
// //       legend: {
// //         display: true,
// //         position: "top",
// //       },
// //       tooltip: {
// //         callbacks: {
// //           label: (context) => `${context.raw} million`,
// //         },
// //       },
// //     },
// //     scales: {
// //       y: {
// //         beginAtZero: true,
// //         title: {
// //           display: true,
// //           text: "Population (in millions)",
// //         },
// //       },
// //     },
// //   };

// //   return (
// //     <div className=" bg-gradient-to-b from-blue-50 to-blue-100 p-8">
// //       <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
// //         <h1 className="text-3xl font-bold text-center text-gray-800 mb-3 mt-20">
// //           Gender-Wise Population in Utah
// //         </h1>
// //         <p className="text-center text-gray-600 mb-4 pt-4">
// //           This chart represents the gender-wise population distribution for Utah.
// //         </p>

// //         {/* Chart Section */}
// //         <div className="relative h-96">
// //           <Bar data={data} options={options} />
// //         </div>

// //         {/* Details Section */}
// //         <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-6">
// //           <div className="flex items-center bg-blue-100 rounded-lg p-4">
// //             <div className="bg-blue-500 text-white rounded-full h-12 w-12 flex justify-center items-center mr-4">
// //               M
// //             </div>
// //             <div>
// //               <h3 className="text-xl font-semibold text-gray-800">Male Population</h3>
// //               <p className="text-gray-600">{populationData.male} million</p>
// //             </div>
// //           </div>
// //           <div className="flex items-center bg-pink-100 rounded-lg p-4">
// //             <div className="bg-pink-500 text-white rounded-full h-12 w-12 flex justify-center items-center mr-4">
// //               F
// //             </div>
// //             <div>
// //               <h3 className="text-xl font-semibold text-gray-800">Female Population</h3>
// //               <p className="text-gray-600">{populationData.female} million</p>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default GenderPopulationPage;














// import React from "react";

// const GenderPopulationPage = () => {
//   return (
//     <div className="my-0 flex max-w-full flex-col lg:flex-row lg:flex-wrap">
//       {/* Population Pyramid Section */}
//       <div className="lg:w-8/12 border-r px-2 py-4 md:px-4 lg:px-8">
//         <h2 className="text-lg font-bold mb-4">Utah Population Pyramid 2024</h2>
//         <div className="center">
//           <div className="relative">
//             {/* SVG Placeholder */}
//             <svg width="411" height="287.7">
//               {/* Add your SVG content here */}
//               <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
//                 Population Pyramid Chart Placeholder
//               </text>
//             </svg>
//           </div>
//         </div>
//       </div>

//       {/* Sidebar Section */}
//       <div className="lg:w-4/12 border-r px-2 py-4 md:px-4 lg:px-8">
//         {/* Utah Median Age */}
//         <div className="mt-6">
//           <h2 className="text-center lg:text-left font-bold">Utah Median Age</h2>
//           <div className="median-age-container mt-6 flex flex-row justify-evenly lg:flex-col lg:justify-start">
//             <div className="mb-3 flex flex-col items-center justify-start lg:flex-row">
//               <div className="vr-val lg:mr-3">
//                 <p className="m-0 text-3xl leading-none">31.4</p>
//               </div>
//               <div className="vr-label">
//                 <p className="m-0">Total</p>
//               </div>
//             </div>
//             <div className="mb-3 flex flex-col items-center justify-start lg:flex-row">
//               <div className="vr-val lg:mr-3">
//                 <p className="m-0 text-3xl leading-none">30.9</p>
//               </div>
//               <div className="vr-label">
//                 <p className="m-0">Male</p>
//               </div>
//             </div>
//             <div className="mb-3 flex flex-col items-center justify-start lg:flex-row">
//               <div className="vr-val lg:mr-3">
//                 <p className="m-0 text-3xl leading-none">31.9</p>
//               </div>
//               <div className="vr-label">
//                 <p className="m-0">Female</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Utah Adults */}
//         <h2 className="text-center lg:text-left font-bold mt-6">Utah Adults</h2>
//         <p className="text-center lg:text-left">
//           There are 2,347,810 adults, (374,344 of whom are seniors) in Utah.
//         </p>

//         {/* Utah Age Dependency */}
//         <div className="clear-both my-6 p-0 text-center lg:text-left">
//           <h2 className="mb-3 font-bold">Utah Age Dependency</h2>
//           <div className="mb-3 flex flex-col items-center justify-start lg:flex-row">
//             <div className="vr-val lg:mr-3">
//               <p className="m-0 text-3xl leading-none">66.4</p>
//             </div>
//             <div className="vr-label">
//               <p className="m-0">Age Dependency Ratio</p>
//             </div>
//           </div>
//           <div className="mb-3 flex flex-col items-center justify-start lg:flex-row">
//             <div className="vr-val lg:mr-3">
//               <p className="m-0 text-3xl leading-none">19</p>
//             </div>
//             <div className="vr-label">
//               <p className="m-0">Old Age Dependency Ratio</p>
//             </div>
//           </div>
//           <div className="mb-3 flex flex-col items-center justify-start lg:flex-row">
//             <div className="vr-val lg:mr-3">
//               <p className="m-0 text-3xl leading-none">47.4</p>
//             </div>
//             <div className="vr-label">
//               <p className="m-0">Child Dependency Ratio</p>
//             </div>
//           </div>
//         </div>

//         {/* Utah Sex Ratio */}
//         <h2 className="text-center lg:text-left font-bold">Utah Sex Ratio</h2>
//         <table className="table-auto mx-auto lg:mx-0">
//           <tbody>
//             <tr>
//               <td className="border px-4 py-2 font-bold">Female</td>
//               <td className="border px-4 py-2">1,618,600</td>
//               <td className="border px-4 py-2">49.29%</td>
//             </tr>
//             <tr>
//               <td className="border px-4 py-2 font-bold">Male</td>
//               <td className="border px-4 py-2">1,665,210</td>
//               <td className="border px-4 py-2">50.71%</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default GenderPopulationPage;



















// import React from "react";
// import { Bar } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

// // Register the required Chart.js components
// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
// const populationBackground = "/img/population-background5.png"

// const GenderPopulationPage = () => {
//   // Data for the pyramid chart
//   const data = {
//     labels: [
//       "0-4",
//       "5-9",
//       "10-14",
//       "15-19",
//       "20-24",
//       "25-29",
//       "30-34",
//       "35-39",
//       "40-44",
//       "45-49",
//       "50-54",
//       "55-59",
//       "60-64",
//       "65-69",
//       "70-74",
//       "75-79",
//       "80+",
//     ],
//     datasets: [
//       {
//         label: "Male",
//         data: [
//           123087, 134775, 139741, 136827, 144829, 129799, 116488, 121284, 111535,
//           96484, 82000, 77014, 75909, 63005, 47432, 30292, 19246,
//         ],
//         backgroundColor: "#26689B",
//       },
//       {
//         label: "Female",
//         data: [
//           -116430, -126888, -131923, -132695, -136695, -121135, -111707, -113014,
//           -107767, -89440, -78338, -77198, -76463, -64980, -52453, -35027, -24550,
//         ],
//         backgroundColor: "#B815BA",
//       },
//     ],
//   };

//   // Chart options
//   const options = {
//     indexAxis: "y", // Horizontal bar chart
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//       },
//       tooltip: {
//         callbacks: {
//           label: (context) => {
//             const value = Math.abs(context.raw).toLocaleString();
//             return `${context.dataset.label}: ${value}`;
//           },
//         },
//       },
//     },
//     scales: {
//       x: {
//         ticks: {
//           callback: (value) => Math.abs(value), // Show positive values for both sides
//         },
//       },
//     },
//   };

//   return (
  
//         <div
//           className=" flex flex-col bg-cover bg-center bg-[#d2eaff] "
//           style={{
            
//             paddingTop: "13vh",
//           }}
//         >
//         <div
//               style={{
//     // backgroundImage: `url(${populationBackground})`,
//     backgroundSize: "cover", // Ensures the image covers the whole container
//     backgroundPosition: "center", // Centers the image
//     backgroundRepeat: "no-repeat", // Prevents the image from repeating
  
//   }}>
//           <div className="my-0 flex max-w-full flex-col lg:flex-row lg:flex-wrap">
//             {/* Population Pyramid Section */}
//             <div className="lg:w-8/12 border-r px-2 py-4 md:px-4 lg:px-8 rounded-lg shadow-md"
//             >
//               <h2 className="text-5xl font-bold text-center mb-4">Utah Population Pyramid 2024</h2>
//               <div className="center">
//                 <Bar data={data} options={options} />
//               </div>
//             </div>
      















import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Legend, Filler } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
 import Tooltip from 'react-bootstrap/Tooltip';

// Register required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Legend, Filler);

const populationBackground = "/img/population-background5.png";

const GenderPopulationPage = () => {
  // Combination Chart Data
  const data = {
    labels: [
      "0-4", "5-9", "10-14", "15-19", "20-24", "25-29", "30-34", "35-39", "40-44", "45-49",
      "50-54", "55-59", "60-64", "65-69", "70-74", "75-79", "80+",
    ],
    datasets: [
      {
        type: "bar", // Bar chart for Males
        label: "Male Population",
        data: [123087, 134775, 139741, 136827, 144829, 129799, 116488, 121284, 111535, 96484, 82000, 77014, 75909, 63005, 47432, 30292, 19246],
        backgroundColor: "#26689B",
      },
      {
        type: "bar", // Bar chart for Females
        label: "Female Population",
        data: [116430, 126888, 131923, 132695, 136695, 121135, 111707, 113014, 107767, 89440, 78338, 77198, 76463, 64980, 52453, 35027, 24550],
        backgroundColor: "#B815BA",
      },
      {
        type: "line", // Line chart for Total Population
        label: "Total Population",
        data: [239517, 261663, 271664, 269522, 281524, 250934, 228195, 234298, 219302, 185924, 160338, 154212, 152372, 127985, 99885, 65319, 43796],
        borderColor: "#FFAA1D",
        backgroundColor: "rgba(255, 170, 29, 0.5)",
        borderWidth: 2,
        pointBackgroundColor: "#FFAA1D",
        tension: 0.3, // Adds smoothness to the line
      },
      {
        type: "line", // Area Chart for Total Population Trend
        label: "Population Trend (Area)",
        data: [239517, 261663, 271664, 269522, 281524, 250934, 228195, 234298, 219302, 185924, 160338, 154212, 152372, 127985, 99885, 65319, 43796],
        borderColor: "rgba(72, 148, 255, 0.8)",
        backgroundColor: "rgba(72, 148, 255, 0.3)",
        borderWidth: 1,
        fill: true, // Enables the area fill
        tension: 0.3,
      },
    ],
  };

  // Combination Chart Options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Utah Population - Bar, Line, and Area Combination Chart (2024)",
        font: { size: 18 },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`,
        },
      },
    },
    scales: {
      x: {
        title: { display: true, text: "Age Groups" },
      },
      y: {
        title: { display: true, text: "Population" },
        beginAtZero: true,
      },
    },
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Age Dependency:<br/>
      100 x (Population (0 to 14) + Population (65+)) / Population (15 to 64)
    </Tooltip>
  );

  return (
    <div
      className="flex flex-col bg-cover bg-center bg-[#d2eaff]"
      style={{ paddingTop: "13vh" }}
    >
      <div
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="my-0 flex max-w-full flex-col lg:flex-row lg:flex-wrap">
          <div className="lg:w-8/12 border-r px-2 py-4 md:px-4 lg:px-8 rounded-lg shadow-md">
            <h2 className="text-5xl font-bold text-center mb-4">
              Utah Population 2024
              
            </h2>
            <div className="text-center text-lg">(Total population 3.45 million September  2024)</div>
            {/* <div className="center">
              
              <Line data={data} options={options} />
            </div> */}

            <div className="center h-[30rem] sm:h-[40rem] w-full  mx-auto p-4">
  {/* Combination Chart */}
  <Line
    data={data}
    options={{
      ...options,
      responsive: true,
      maintainAspectRatio: false, // Allows the chart to resize dynamically
      plugins: {
        legend: {
          display: true,
          position: "top", // Adjust legend position
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 12, // Adjust font size for better responsiveness
            },
          },
        },
        y: {
          ticks: {
            font: {
              size: 12,
            },
          },
        },
      },
    }}
  />
</div>

          </div>
       
     



            {/* Sidebar Section */}
            <div className="lg:w-4/12 border-r px-2 py-4 md:px-4 lg:px-8  rounded-lg shadow-md">
              {/* Sidebar Content */}
              <div className="mt-6 text-center">
                <h2 className="font-bold">Utah Median Age</h2>
                <div className="median-age-container mt-6 flex flex-col items-center justify-center">
                  <div className="mb-3 flex flex-col items-center">
                    <div className="vr-val">
                      <p className="m-0 text-3xl leading-none">31.4</p>
                    </div>
                    <div className="vr-label">
                      <p className="m-0">Total</p>
                    </div>
                  </div>
                  <div className="mb-3 flex flex-col items-center">
                    <div className="vr-val">
                      <p className="m-0 text-3xl leading-none">30.9</p>
                    </div>
                    <div className="vr-label">
                      <p className="m-0">Male</p>
                    </div>
                  </div>
                  <div className="mb-3 flex flex-col items-center">
                    <div className="vr-val">
                      <p className="m-0 text-3xl leading-none">31.9</p>
                    </div>
                    <div className="vr-label">
                      <p className="m-0">Female</p>
                    </div>
                  </div>
                </div>
              </div>
      
              <h2 className="text-center lg:text-left font-bold mt-6">Utah Adults</h2>
              <p className="text-center lg:text-left">
                There are 2,347,810 adults, (374,344 of whom are seniors) in Utah.
              </p>
              <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
     
    >
              <div>
              <div className="clear-both my-6 p-0 text-center lg:text-left">
             
                <h2 className="mb-3 font-bold">
                
                <span>Utah Age Dependency</span>
               
                </h2>
             
                <div className="mb-3 flex flex-col items-center justify-center text-center">
                
                  <div className="vr-val">
                  
                    <p className="m-0 text-3xl leading-none">66.4</p>
                  </div>
                  <div className="vr-label">
                    <p className="m-0">Age Dependency Ratio</p>
                  </div>
                  
                </div>
               
              </div>
              </div>
              </OverlayTrigger>
      
              <h2 className="text-center lg:text-left font-bold">Utah Sex Ratio</h2>
              <table className="table-auto mx-auto lg:mx-0">
                <tbody>
                  <tr>
                    <td className="border px-4 py-2 font-bold">Female</td>
                    <td className="border px-4 py-2">1,618,600</td>
                    <td className="border px-4 py-2">49.29%</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2 font-bold">Male</td>
                    <td className="border px-4 py-2">1,665,210</td>
                    <td className="border px-4 py-2">50.71%</td>
                  </tr>
                </tbody>
              </table>

              <span >Note: The population data displayed on this website is accurate as of September 2024. Future updates will reflect any changes in demographics.</span>
            </div>
          </div>
          </div>
        </div>
      );
      
//     <div className="my-0 flex max-w-full flex-col lg:flex-row lg:flex-wrap" style={{paddingTop:"15vh"}}>
//       {/* Population Pyramid Section */}
//       <div className="lg:w-8/12 border-r px-2 py-4 md:px-4 lg:px-8">
//         <h2 className="text-xl font-bold text-center mb-4">Utah Population Pyramid 2024</h2>
//         <div className="center">
//           <Bar data={data} options={options} />
//         </div>
//       </div>

//       {/* Sidebar Section */}
//       <div className="lg:w-4/12 border-r px-2 py-4 md:px-4 lg:px-8">
//         {/* Sidebar content remains the same */}
//         {/* <div className="mt-6">
//           <h2 className="text-center lg:text-left font-bold">Utah Median Age</h2>
//           <div className="median-age-container mt-6 flex flex-row justify-evenly lg:flex-col lg:justify-start">
//             <div className="mb-3 flex flex-col items-center justify-start lg:flex-row">
//               <div className="vr-val lg:mr-3">
//                 <p className="m-0 text-3xl leading-none">31.4</p>
//               </div>
//               <div className="vr-label">
//                 <p className="m-0">Total</p>
//               </div>
//             </div>
//             <div className="mb-3 flex flex-col items-center justify-start lg:flex-row">
//               <div className="vr-val lg:mr-3">
//                 <p className="m-0 text-3xl leading-none">30.9</p>
//               </div>
//               <div className="vr-label">
//                 <p className="m-0">Male</p>
//               </div>
//             </div>
//             <div className="mb-3 flex flex-col items-center justify-start lg:flex-row">
//               <div className="vr-val lg:mr-3">
//                 <p className="m-0 text-3xl leading-none">31.9</p>
//               </div>
//               <div className="vr-label">
//                 <p className="m-0">Female</p>
//               </div>
//             </div>
//           </div>
//         </div> */}

//         <div className="mt-6 text-center">
//   <h2 className="font-bold">Utah Median Age</h2>
//   <div className="median-age-container mt-6 flex flex-col items-center justify-center">
//     <div className="mb-3 flex flex-col items-center">
//       <div className="vr-val">
//         <p className="m-0 text-3xl leading-none">31.4</p>
//       </div>
//       <div className="vr-label">
//         <p className="m-0">Total</p>
//       </div>
//     </div>
//     <div className="mb-3 flex flex-col items-center">
//       <div className="vr-val">
//         <p className="m-0 text-3xl leading-none">30.9</p>
//       </div>
//       <div className="vr-label">
//         <p className="m-0">Male</p>
//       </div>
//     </div>
//     <div className="mb-3 flex flex-col items-center">
//       <div className="vr-val">
//         <p className="m-0 text-3xl leading-none">31.9</p>
//       </div>
//       <div className="vr-label">
//         <p className="m-0">Female</p>
//       </div>
//     </div>
//   </div>
// </div>


//         <h2 className="text-center lg:text-left font-bold mt-6">Utah Adults</h2>
//         <p className="text-center lg:text-left">
//           There are 2,347,810 adults, (374,344 of whom are seniors) in Utah.
//         </p>

//         <div className="clear-both my-6 p-0 text-center lg:text-left">
//           <h2 className="mb-3 font-bold">Utah Age Dependency</h2>
//           {/* <div className="mb-3 flex flex-col items-center justify-start lg:flex-row">
//             <div className="vr-val lg:mr-3">
//               <p className="m-0 text-3xl text-center leading-none">66.4</p>
//             </div>
//             <div className="vr-label">
//               <p className="m-0">Age Dependency Ratio</p>
//             </div>
//           </div> */}
//           <div className="mb-3 flex flex-col items-center justify-center text-center">
//   <div className="vr-val">
//     <p className="m-0 text-3xl leading-none">66.4</p>
//   </div>
//   <div className="vr-label">
//     <p className="m-0">Age Dependency Ratio</p>
//   </div>
// </div>

//         </div>
//         <h2 className="text-center lg:text-left font-bold">Utah Sex Ratio</h2>
//         <table className="table-auto mx-auto lg:mx-0">
//           <tbody>
//             <tr>
//               <td className="border px-4 py-2 font-bold">Female</td>
//               <td className="border px-4 py-2">1,618,600</td>
//               <td className="border px-4 py-2">49.29%</td>
//             </tr>
//             <tr>
//               <td className="border px-4 py-2 font-bold">Male</td>
//               <td className="border px-4 py-2">1,665,210</td>
//               <td className="border px-4 py-2">50.71%</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
};

export default GenderPopulationPage;
