// import React from 'react';
// import Table from 'react-bootstrap/Table';
// import { Bar } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const AeroTable12 = ({ data }) => {
//   // Prepare data for the chart
//   const chartData = {
//     labels: data.map(item => item.age_group_label),
//     datasets: [
//       {
//         label: 'Male Employment in 2023',
//         data: data.map(item => parseInt(item.male_2023, 10)),
//         backgroundColor: 'rgba(54, 162, 235, 0.6)',
//         borderColor: 'rgba(54, 162, 235, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   const chartOptions = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//       tooltip: {
//         callbacks: {
//           label: context => `Count: ${context.raw}`,
//         },
//       },
//     },
//     scales: {
//       y: {
//         beginAtZero: true,
//         title: {
//           display: true,
//           text: 'Number of Male Workers',
//         },
//       },
//       x: {
//         title: {
//           display: true,
//           text: 'Age Group',
//         },
//       },
//     },
//   };

//   return (
//     <div className="container">
//       {/* Table Section */}
//       <div className="table-responsive">
//         <Table striped bordered hover responsive>
//           <thead>
//             <tr>
//               <th>Age Group</th>
//               <th>Male Employment (2023)</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data.map((item, index) => (
//               <tr key={index}>
//                 <td>{item.age_group_label}</td>
//                 <td>{item.male_2023}</td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//       </div>

//       <div
//   style={{
//     overflowX: "auto", // Enables horizontal scroll
//     display: "flex",
//     justifyContent: "center",
//     width: "100%", // Takes full width
//   }}
// >
//   <div
//     style={{
//       minWidth: "600px", // Minimum width to enable scroll
//       width: "1000px",   // Chart width, ensuring horizontal scroll on smaller screens
//       height: "400px",
//     }}
//   >
//     <Bar data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
//   </div>
// </div>
//     </div>
//   );
// };

// export default AeroTable12;

import React, { useRef } from "react";
import Table from "react-bootstrap/Table";
import { Bar } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// Import images for PDF header and footer

// Register Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

const AeroTable12 = ({ data }) => {
  const pdfRef = useRef(); // Reference for the content to export as PDF

  // Chart data and options
  const chartData = {
    labels: data.map((item) => item.age_group_label),
    datasets: [
      {
        label: "Male Employment in 2023",
        data: data.map((item) => parseInt(item.male_2023, 10)),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      tooltip: {
        callbacks: {
          label: (context) => `Count: ${context.raw}`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: { display: true, text: "Number of Male Workers" },
      },
      x: { title: { display: true, text: "Age Group" } },
    },
  };

  // Function to download PDF
  const downloadPDF = () => {
    const input = pdfRef.current;
    input.style.width = "1100px"; // Expand width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });
      pdf.setFontSize(12);
      pdf.line(10, 35, 200, 35);

      // Main Content
      pdf.addImage(imgData, "PNG", 5, 38, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260);
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.text("Built by", 55, footerStartY + 10);
      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
      pdf.text("20250106.v1.85", 148, footerStartY + 10);
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("male-employment-2023.pdf");
      input.style.width = ""; // Reset width
    });
  };

  return (
    <div className="container">
      <div ref={pdfRef} className="content-to-export">
        {/* Table Section */}
        <div className="table-responsive">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Age Group</th>
                <th>Male Employment (2023)</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.age_group_label}</td>
                  <td>{item.male_2023}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* Chart Section */}
        {/* <div
          style={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              minWidth: "600px",
              width: "1000px",
              height: "400px",
            }}
          > */}

        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
              height: "400px",
            }}
          >
            <Bar data={chartData} options={chartOptions} />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center mt-4">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
          <span>
            <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AeroTable12;
