import React, { useRef, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import "../styles/AboutUs.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


import { Link } from "react-router-dom";
import "../styles/Aerospace.css";
import ModalFunction from "./Modal";
const census = "/img/census.png";


const logo = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const AboutUsImage = "/img/aboutus1.png"
const sourcelogo1 = "/img/unemployment-logo.png"
const sourcelogo2 = "/img/utah-job-logo.png"

export default function AboutUs() {
  const [modalShow, setModalShow] = React.useState(false);
  const iconData = [
    { icon: "fa-fighter-jet", title: "Aerospace and Defense" },
    { icon: "fa-seedling", title: "Agriculture, Food, and Natural Resources" },
    { icon: "fa-briefcase", title: "Business, Finance, and Marketing" },
    { icon: "fa-cogs", title: "Engineering and Technology" },
    { icon: "fa-hands-helping", title: "Human Services" },
    { icon: "fa-truck", title: "Transportation, Distribution, and Logistics" },
    { icon: "fa-building", title: "Architecture and Construction" },
    { icon: "fa-laptop-code", title: "Computer Science and IT" },
    { icon: "fa-heartbeat", title: "Health Science" },
    {
      icon: "fa-balance-scale",
      title: "Law, Public Safety, Corrections, and Security",
    },
    {
      icon: "fa-paint-brush",
      title: "Arts, Audio/Visual Technology, and Communication",
    },
    { icon: "fa-graduation-cap", title: "Education and Training" },
    { icon: "fa-concierge-bell", title: "Hospitality and Tourism" },
    { icon: "fa-industry", title: "Manufacturing" },
  ];

  const navbarRef = useRef(null);

  // Close the navbar on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse =
          navbarRef.current.querySelector(".navbar-collapse");
        if (navbarCollapse) {
          navbarCollapse.classList.remove("show"); // Close the navbar
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="main-wrapper flex flex-col">
    
   
      {/* Main content */}
      <div
  className=" bg-[#d2eaff] flex flex-col items-center justify-center"
  style={{
    backgroundImage: `url(${AboutUsImage})`, // Replace with your image path
    backgroundSize: "cover", // Ensures the image covers the entire container
    backgroundPosition: "center", // Centers the image
    backgroundRepeat: "no-repeat", // Prevents the image from repeating
    width: "100%", // Ensures the container takes full width of the screen
    height: "45vh", // Makes the container span the full height of the viewport
  }}
>
  <h2
    className="text-white text-center px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl mt-7"
    style={{
      color: "white",
      textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)", // Makes the text stand out
      lineHeight: "1.2", // Adjusts line spacing
    }}
  >
    UTahData.org
  </h2>

  <span
    className="text-center px-4 text-lg sm:text-2xl md:text-3xl lg:text-4xl mt-4"
    style={{
      color: "black",
      fontFamily: "SignPainter", // Use the SignPainter font
      textShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)", // Adds subtle shadow
      lineHeight: "1.5", // Adjusts line spacing
    }}
  >
    Explore Utah By The Numbers
  </span>
</div>

          <div className="bg-[#d2eaff] h-auto sm:h-[90vh]">
          
          <div className="mt-[3rem] px-4 sm:px-0">
        <p className="about-us-content ">
        At UtahData.org, we’re proud to call Utah home. Our mission is to empower Utahns with access to comprehensive, interactive, and user-friendly data insights 
        that celebrate and illuminate our state’s rich diversity and dynamic growth.
        </p>
        <p className="about-us-content">
        We’ve built a platform that brings together key statistics and trends across a variety of sectors—Manufacturing, Defense and Aerospace, agriculture, business, 
        engineering, technology, health, and education to name a few.
        </p>
        <p className="about-us-content">
        By visualizing data from trusted sources like the U.S. Census and other reliable organizations, we make it easy for anyone to explore and analyze Utah’s 
        demographics, industries, and economic landscape.
        </p>
        <p className="about-us-content">
        Whether you’re a researcher, policymaker, business leader, or simply a curious citizen, UtahData.org is here to help you uncover meaningful insights and 
        make informed decisions. Together, let’s better understand the numbers that shape our state’s story.
        </p>
        </div>
        {/* <div className='mx-auto flex justify-center text-center mb-[0rem]'>
        <div>Sources of Data</div><br/>
          <div><img src={census}/></div>
          
          
        </div> */}
        <div className="mx-auto flex flex-col items-center text-center mb-0">
          <div className=" text-lg font-medium">Sources of Data :</div>
          {/* <div className="flex">
           
            <img src={sourcelogo1} className="mr-[2rem] w-[7rem]"></img>
              <img
                src={census}
                alt="Sources of Data"
                className="max-w-full h-auto mr-[2rem]"
              />
              <img src={sourcelogo2} className="w-[20rem]"></img>

            
          </div> */}

          <div className="flex flex-wrap items-center mb-[2rem] justify-center gap-4">
  {/* Source Logo 1 */}
 

  {/* Census Logo */}
  <img
    src={census}
    alt="Sources of Data"
    className="max-w-full h-auto w-[10rem] sm:w-[12rem] md:w-[14rem] lg:w-[16rem]"
  />

  {/* Source Logo 2 */}
  <img
    src={sourcelogo2}
    alt="Source Logo 2"
    className="w-[10rem] sm:w-[15rem] md:w-[18rem] lg:w-[20rem] h-auto"
  />
   <img
    src={sourcelogo1}
    alt="Source Logo 1"
    className="w-[4rem] sm:w-[5rem] md:w-[6rem] lg:w-[7rem] h-auto"
  /><p className="text-lg">U.S. Bureau of Labour Statistics</p>
</div>

        </div>
        </div>

      {/* Footer */}
     
    </div>
  );
}
