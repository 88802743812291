// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const TableNo32 = () => {
//   // Data for the table and chart
//   const educationGenderShareData = [
//     {
//       educationLevel: 'High school or equivalent, no college',
//       femaleShare2018: 24.44,
//       maleShare2018: 25.32,
//       femaleShare2023: 22.91,
//       maleShare2023: 24.67,
//     },
//   ];

//   // Chart data
//   const chartData = {
//     labels: educationGenderShareData.map(item => item.educationLevel),
//     datasets: [
//       {
//         label: 'Female Share % (2018)',
//         data: educationGenderShareData.map(item => item.femaleShare2018),
//         backgroundColor: 'rgba(255, 99, 132, 0.6)',
//         borderColor: 'rgba(255, 99, 132, 1)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Male Share % (2018)',
//         data: educationGenderShareData.map(item => item.maleShare2018),
//         backgroundColor: 'rgba(54, 162, 235, 0.6)',
//         borderColor: 'rgba(54, 162, 235, 1)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Female Share % (2023)',
//         data: educationGenderShareData.map(item => item.femaleShare2023),
//         backgroundColor: 'rgba(255, 206, 86, 0.6)',
//         borderColor: 'rgba(255, 206, 86, 1)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Male Share % (2023)',
//         data: educationGenderShareData.map(item => item.maleShare2023),
//         backgroundColor: 'rgba(75, 192, 192, 0.6)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Chart options
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       y: {
//         beginAtZero: true,
//         ticks: {
//           callback: (value) => `${value}%`, // Display percentage symbol on the Y-axis
//         },
//       },
//     },
//     plugins: {
//       tooltip: {
//         callbacks: {
//           label: (context) => `${context.dataset.label}: ${context.raw}%`, // Show percentage in tooltip
//         },
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h2>Education Level Share by Gender (2018 vs 2023)</h2>

//       {/* Responsive Table */}
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Education Level</th>
//             <th>Female Share % (2018)</th>
//             <th>Male Share % (2018)</th>
//             <th>Female Share % (2023)</th>
//             <th>Male Share % (2023)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {educationGenderShareData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.educationLevel}</td>
//               <td>{item.femaleShare2018}%</td>
//               <td>{item.maleShare2018}%</td>
//               <td>{item.femaleShare2023}%</td>
//               <td>{item.maleShare2023}%</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Bar Chart */}
//       <div
//           style={{
//             overflowX: "auto", // Enables horizontal scroll
//             display: "flex",
//             justifyContent: "center",
//             width: "100%", // Takes full width
//           }}
//         >
//           <div
//             style={{
//               minWidth: "600px", // Minimum width to enable scroll
//               width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
//               height: "400px",
//             }}
//           >
//         <Bar data={chartData} options={options} />
//       </div>
//       </div>
//     </div>
//   );
// };

// export default TableNo32;

import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TableNo32 = () => {
  const pdfRef = useRef();

  // Data for the table and chart
  const educationGenderShareData = [
    {
      educationLevel: "High school or equivalent, no college",
      femaleShare2018: 24.44,
      maleShare2018: 25.32,
      femaleShare2023: 22.91,
      maleShare2023: 24.67,
    },
  ];

  // Chart data
  const chartData = {
    labels: educationGenderShareData.map((item) => item.educationLevel),
    datasets: [
      {
        label: "Female Share % (2018)",
        data: educationGenderShareData.map((item) => item.femaleShare2018),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "Male Share % (2018)",
        data: educationGenderShareData.map((item) => item.maleShare2018),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Female Share % (2023)",
        data: educationGenderShareData.map((item) => item.femaleShare2023),
        backgroundColor: "rgba(255, 206, 86, 0.6)",
        borderColor: "rgba(255, 206, 86, 1)",
        borderWidth: 1,
      },
      {
        label: "Male Share % (2023)",
        data: educationGenderShareData.map((item) => item.maleShare2023),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}%`,
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1200px";

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);

      pdf.setFontSize(12);
      pdf.line(10, 35, 200, 35);

      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

      pdf.line(10, 260, 200, 260);
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
      pdf.text("20250106.v1.85", 148, 275);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      pdf.save("education-gender-share.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="container">
      <h2>Education Level Share by Gender (2018 vs 2023)</h2>

      {/* PDF Content */}
      <div ref={pdfRef}>
        {/* Responsive Table */}
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Education Level</th>
              <th>Female Share % (2018)</th>
              <th>Male Share % (2018)</th>
              <th>Female Share % (2023)</th>
              <th>Male Share % (2023)</th>
            </tr>
          </thead>
          <tbody>
            {educationGenderShareData.map((item, index) => (
              <tr key={index}>
                <td>{item.educationLevel}</td>
                <td>{item.femaleShare2018}%</td>
                <td>{item.maleShare2018}%</td>
                <td>{item.femaleShare2023}%</td>
                <td>{item.maleShare2023}%</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Bar Chart */}
        {/* <div
          style={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              minWidth: "600px",
              width: "1000px",
              height: "400px",
            }}
          > */}

        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
              height: "400px",
            }}
          >
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
        </button>
      </div>
    </div>
  );
};

export default TableNo32;
