import React, { useRef, useState, useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";

import "../styles/Faq.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndustry,
  faChartLine,
  faCogs,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";

import { Link, useLocation } from "react-router-dom";
import { FaqSection } from "./FaqSection";
//import { FaqSection } from './FaqSection';
import "../styles/Aerospace.css";
import ModalFunction from "./Modal";

const logo = "/img/utahData-logo.png";
const kpi = "/img/KPI-DASH 2.png";

export default function NewNavbar() {

  const [expanded, setExpanded] = useState(false);
  const [expandSize, setExpandSize] = useState("lg"); // Default expand size
    const [isExpanded, setIsExpanded] = useState(false);
    const location = useLocation();
    const navbarRef = useRef(null);
    const [modalShow, setModalShow] = React.useState(false);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState("");
    const navigate = useNavigate();
  
    // Check login status on mount
    // useEffect(() => {
    //   const storedUser = JSON.parse(localStorage.getItem("user"));
    //   if (storedUser && storedUser.name) {
    //     setIsLoggedIn(true);
    //     setUserName(storedUser.name);
    //   }
    // }, []);
  
    // // Logout handler
    // const handleLogout = () => {
    //   localStorage.removeItem("user"); // Clear user data
    //   setIsLoggedIn(false);
    //   setUserName("");
    //   navigate("/login"); // Redirect to login page
    // };


    useEffect(() => {
      const checkLoginStatus = () => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (storedUser && storedUser.name) {
          setIsLoggedIn(true);
          setUserName(storedUser.name);
        } else {
          setIsLoggedIn(false);
          setUserName("");
        }
      };
  
      checkLoginStatus();
  
      // Listen for changes in localStorage
      window.addEventListener("storage", checkLoginStatus);
  
      return () => {
        window.removeEventListener("storage", checkLoginStatus);
      };
    }, []);
  
    const handleLogout = () => {
      localStorage.removeItem("user");
      setIsLoggedIn(false);
      setUserName("");
      navigate("/login");
    };
  


    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 1700) {
          setExpandSize(false); // Collapse for screens 1024px and below
        } else {
          setExpandSize("lg"); // Use "lg" for larger screens
        }
      };
  
      handleResize(); // Set on initial render
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
  
    // Close dropdown when clicking outside the navbar
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
          setExpanded(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    useEffect(() => {
      setExpanded(false);
    }, [location]);
  return (
    <div>
       <Navbar
        ref={navbarRef} // Add ref here
        collapseOnSelect
        expand={expandSize}
        expanded={expanded}
       // onToggle={() => setExpanded(!expanded)}
       onToggle={(isExpanded) => setExpanded(isExpanded)}
        className="fixed top-0 left-0 w-full z-10 flex justify-center items-center"
        style={{
          height: "13vh",
          backgroundColor: "#1e59be",
          zIndex: 5,
          transition: "background-color 0.3s ease",
          position: "fixed",
        }}
      >
        <Container className="md:!ml-[8rem]">
          <Navbar.Brand as={Link} to="/">
            <div className="flex items-center">
              <img
                className="h-[8vh] md:h-[10vh]"
                src={logo}
                alt="UTahData Logo"
              />
              <h1 className="text-white ml-2 text-lg md:text-4xl">
                UTahData.org
                <span className="beta-label text-xs bg-yellow-500 text-white px-1 py-0.5 rounded-lg">
                  Beta
                </span>
              </h1>
            </div>
          </Navbar.Brand>

          {/* Toggle icon for responsive menu */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav"  />

          <Navbar.Collapse id="responsive-navbar-nav" className="navbar-dropdown ">
            {/* Boxed styling for mobile */}
            <Nav className="d-lg-none bg-white border rounded-lg p-4 shadow-lg flex flex-col items-center"
          >
              <Nav.Link
                as={Link}
                to="/"
                onClick={() => setExpanded(false)}
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                onClick={() => setExpanded(false)}
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/utah-at-glance"
                onClick={() => setExpanded(false)}
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                At a Glance
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/betsy"
                onClick={() => setExpanded(false)}
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Ask Betsy AI
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/slc-ut2034-count-down"
                onClick={() => setExpanded(false)}
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Olympic Countdown
              </Nav.Link>

              {/* <Nav.Link
                as={Link}
                to="/faq"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/contact-us"
                onClick={() => setExpanded(false)}
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => setExpanded(false)}
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>

              {/* <Nav.Link
                as={Link}
                to="/login"
                onClick={() => setExpanded(false)}
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Login
              </Nav.Link> */}

              {isLoggedIn ? (
        <>
          {/* <span className="mx-[0.5vw] !text-[#FFAA1D] !font-bold">
            Welcome, {userName}
          </span> */}
          <Nav.Link
            onClick={() => {
              handleLogout(); // Call logout functionality
              setExpanded(false); // Collapse menu in mobile view
            }}
            className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors ease-in-out"
          >
            Logout
          </Nav.Link>
        </>
      ) : (
        <Nav.Link
          as={Link} // Redirect link
          to="/login" // Route to login page
          onClick={() => setExpanded(false)} // Collapse menu in mobile view
          className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors ease-in-out"
        >
          Login
        </Nav.Link>
      )}
            </Nav>

            {/* Inline styling for larger screens */}
            <Nav className="d-none d-lg-flex ml-[8rem]">
              <Nav.Link
                as={Link}
                to="/"
                className="mx-[0.5vw] whitespace-nowrap !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mx-[0.5vw] whitespace-nowrap !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>


              <Nav.Link
                as={Link}
                to="/utah-at-glance"
                className="mx-[0.5vw] whitespace-nowrap !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                At a Glance
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/betsy"
                className="mx-[0.5vw] whitespace-nowrap !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Ask Betsy AI
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/slc-ut2034-count-down"
                className="mx-[0.5vw] whitespace-nowrap !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Olympic Countdown
              </Nav.Link>
              <ModalFunction
                show={modalShow}
                onHide={() => setModalShow(false)}
              />

              {/* <Nav.Link
                as={Link}
                to="/faq"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="mx-[0.5vw] whitespace-nowrap !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="mx-[0.5vw] whitespace-nowrap !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>

              {isLoggedIn ? (
        <>
          {/* <span className="mx-[0.5vw] !text-[#FFAA1D] !font-bold">
            Welcome, {userName}
          </span> */}
          <Nav.Link
            onClick={handleLogout}
            className="mx-[0.5vw] whitespace-nowrap !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
          >
            Logout
          </Nav.Link>
        </>
      ) : (
        <Nav.Link
          as={Link}
          to="/login"
          className="mx-[0.5vw] whitespace-nowrap !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
        >
          Login
        </Nav.Link>
      )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}





// import React, { useRef, useState, useEffect } from "react";
// import "@fortawesome/fontawesome-free/css/all.min.css";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import Container from "react-bootstrap/Container";

// import "../styles/Faq.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faIndustry,
//   faChartLine,
//   faCogs,
//   faLightbulb,
// } from "@fortawesome/free-solid-svg-icons";

// import { Link, useLocation } from "react-router-dom";
// import { FaqSection } from "./FaqSection";
// import "../styles/Aerospace.css";
// import ModalFunction from "./Modal";

// const logo = "/img/utahData-logo.png";

// export default function NewNavbar() {
//   const [expanded, setExpanded] = useState(false);
//   const [expandSize, setExpandSize] = useState("lg"); // Default expand size
//   const location = useLocation();
//   const navbarRef = useRef(null);
//   const [modalShow, setModalShow] = React.useState(false);

//   // Dynamically adjust expand size based on screen width
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 1024) {
//         setExpandSize(false); // Collapse for screens 1024px and below
//       } else {
//         setExpandSize("lg"); // Use "lg" for larger screens
//       }
//     };

//     handleResize(); // Set on initial render
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   // Close dropdown when clicking outside the navbar
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (navbarRef.current && !navbarRef.current.contains(event.target)) {
//         setExpanded(false);
//       }
//     };
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   // Close expanded menu on route change
//   useEffect(() => {
//     setExpanded(false);
//   }, [location]);

//   return (
//     <div>
//       <Navbar
//         ref={navbarRef}
//         collapseOnSelect
//         expand={expandSize} // Dynamically controlled expand size
//         expanded={expanded}
//         onToggle={(isExpanded) => setExpanded(isExpanded)}
//         className="fixed top-0 left-0 w-full z-10 flex justify-center items-center"
//         style={{
//           height: "13vh",
//           backgroundColor: "#1e59be",
//           zIndex: 5,
//           transition: "background-color 0.3s ease",
//           position: "fixed",
//         }}
//       >
//         <Container className="md:!ml-[8rem]">
//           <Navbar.Brand as={Link} to="/">
//             <div className="flex items-center">
//               <img
//                 className="h-[8vh] md:h-[10vh]"
//                 src={logo}
//                 alt="UTahData Logo"
//               />
//               <h1 className="text-white ml-2 text-lg md:text-4xl">
//                 UTahData.org
//                 <span className="beta-label text-xs bg-yellow-500 text-white px-1 py-0.5 rounded-lg">
//                   Beta
//                 </span>
//               </h1>
//             </div>
//           </Navbar.Brand>

//           <Navbar.Toggle aria-controls="responsive-navbar-nav" />

//           <Navbar.Collapse id="responsive-navbar-nav">
//             {/* Boxed styling for mobile */}
//             <Nav className="d-lg-none bg-white border rounded-lg p-4 shadow-lg flex flex-col items-center">
//               {/* Add your Nav.Links here for mobile */}
//               <Nav.Link
//                 as={Link}
//                 to="/"
//                 onClick={() => setExpanded(false)}
//                 className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
//               >
//                 Home
//               </Nav.Link>
//               <Nav.Link
//                 as={Link}
//                 to="/verticals"
//                 onClick={() => setExpanded(false)}
//                 className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
//               >
//                 Verticals
//               </Nav.Link>
//               {/* Add other mobile links */}
//             </Nav>

//             {/* Inline styling for larger screens */}
//             <Nav className="d-none d-lg-flex ml-[10rem]">
//               {/* Add your Nav.Links here for desktop */}
//               <Nav.Link
//                 as={Link}
//                 to="/"
//                 className="mx-[1rem] whitespace-nowrap !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
//               >
//                 Home
//               </Nav.Link>
//               <Nav.Link
//                 as={Link}
//                 to="/verticals"
//                 className="mx-[1rem] whitespace-nowrap !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
//               >
//                 Verticals
//               </Nav.Link>
//               {/* Add other desktop links */}
//             </Nav>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>
//     </div>
//   );
// }
