// // // import React, { useRef } from "react";
// // // import Table from "react-bootstrap/Table";
// // // import { Line } from "react-chartjs-2";
// // // import html2canvas from "html2canvas";
// // // import jsPDF from "jspdf";

// // // import {
// // //   Chart as ChartJS,
// // //   CategoryScale,
// // //   LinearScale,
// // //   PointElement,
// // //   LineElement,
// // //   Title,
// // //   Tooltip,
// // //   Legend,
// // // } from "chart.js";

// // // ChartJS.register(
// // //   CategoryScale,
// // //   LinearScale,
// // //   PointElement,
// // //   LineElement,
// // //   Title,
// // //   Tooltip,
// // //   Legend
// // // );

// // // // Images for PDF header and footer
// // // const utahData = "/img/1.png";
// // // const kpi = "/img/KPI-DASH 2.png";
// // // const bodex = "/img/bodexLogo.png";
// // // const pdf = "/img/pdf1.png";

// // // const AeroTable23 = ({ data }) => {
// // //   const pdfRef = useRef();

// // //   // Extract years and sex labels from the dataset
// // //   const years = ["2018", "2019", "2020", "2021", "2022", "2023"];
// // //   const sexLabels = {
// // //     "0": "Total",
// // //     "1": "Male",
// // //     "2": "Female",
// // //   };

// // //   // Prepare chart data
// // //   const chartData = {
// // //     labels: years,
// // //     datasets: data.map((item, index) => ({
// // //       label: sexLabels[item.sex_label],
// // //       data: years.map((year) => parseInt(item[`year_${year}`], 10)),
// // //       borderColor: `hsl(${(index * 60) % 360}, 70%, 50%)`,
// // //       backgroundColor: `hsla(${(index * 60) % 360}, 70%, 50%, 0.3)`,
// // //       tension: 0.4,
// // //       fill: true,
// // //     })),
// // //   };

// // //   const downloadPDF = () => {
// // //     const input = pdfRef.current;
// // //     input.style.width = "1100px";

// // //     html2canvas(input, { scale: 3 }).then((canvas) => {
// // //       const imgData = canvas.toDataURL("image/png");
// // //       const pdf = new jsPDF("p", "mm", "a4");
// // //       const imgWidth = 210;
// // //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

// // //       // Header
// // //       pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
// // //       pdf.setFontSize(26);
// // //       pdf.text("UTahData.org", 95, 22);
// // //       pdf.line(10, 35, 200, 35);

// // //       // Main Content
// // //       pdf.addImage(imgData, "PNG", 5, 38, imgWidth, imgHeight);

// // //       // Footer
// // //       pdf.line(10, 260, 200, 260);
// // //       pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
// // //       pdf.setFontSize(10);
// // //       pdf.text("Built by", 55, 275);
// // //       pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
// // //       pdf.text("using", 112, 275);
// // //       pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
// // //       pdf.text("20250106.v1.85", 148, 275);
// // //       const currentDate = new Date().toLocaleDateString();
// // //       pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
// // //       const currentURL = window.location.href;
// // //       pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

// // //       pdf.save("employment-high-school.pdf");
// // //       input.style.width = "";
// // //     });
// // //   };

// // //   return (
// // //     <div className="container">
// // //       <div ref={pdfRef} className="content-to-export">
// // //         <h3 className="mt-3">High School Employment by Year (2018-2023)</h3>

// // //         {/* Table Section */}
// // //         <Table striped bordered hover responsive className="mb-4">
// // //           <thead>
// // //             <tr>
// // //               <th>Sex</th>
// // //               {years.map((year) => (
// // //                 <th key={year}>{year}</th>
// // //               ))}
// // //             </tr>
// // //           </thead>
// // //           <tbody>
// // //             {data.map((item, index) => (
// // //               <tr key={index}>
// // //                 <td>{sexLabels[item.sex_label]}</td>
// // //                 {years.map((year) => (
// // //                   <td key={year}>{item[`year_${year}`]}</td>
// // //                 ))}
// // //               </tr>
// // //             ))}
// // //           </tbody>
// // //         </Table>

// // //         {/* Chart Section */}
// // //         <div
// // //           style={{
// // //             overflowX: "auto",
// // //             display: "flex",
// // //             justifyContent: "center",
// // //             width: "100%",
// // //           }}
// // //         >
// // //           <div
// // //             style={{
// // //               minWidth: "600px",
// // //               width: "1000px",
// // //               height: "400px",
// // //             }}
// // //           >
// // //             <Line
// // //               data={chartData}
// // //               options={{ responsive: true, maintainAspectRatio: false }}
// // //             />
// // //           </div>
// // //         </div>
// // //       </div>

// // //       {/* Download PDF Button */}
// // //       <div className="flex justify-center mt-4">
// // //         <button
// // //           onClick={downloadPDF}
// // //           className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
// // //         >
// // //           <span>Download as PDF</span>
// // //           <span>
// // //             <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
// // //           </span>
// // //         </button>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default AeroTable23;

// // import React, { useRef } from "react";
// // import Table from "react-bootstrap/Table";
// // import { Bar } from "react-chartjs-2";
// // import html2canvas from "html2canvas";
// // import jsPDF from "jspdf";

// // // Import chart.js modules
// // import {
// //   Chart as ChartJS,
// //   CategoryScale,
// //   LinearScale,
// //   BarElement,
// //   Title,
// //   Tooltip,
// //   Legend,
// // } from "chart.js";

// // ChartJS.register(
// //   CategoryScale,
// //   LinearScale,
// //   BarElement,
// //   Title,
// //   Tooltip,
// //   Legend
// // );

// // // Image assets for PDF
// // const utahData = "/img/1.png";
// // const bodex = "/img/bodexLogo.png";

// // const AeroTable23 = ({ data }) => {
// //   const pdfRef = useRef(); // Reference for PDF export

// //   // Extract race labels and years for the chart
// //   const raceLabels = data.map((item) => item.race_label);
// //   const years = ["2018", "2019", "2020", "2021", "2022", "2023"];

// //   // Prepare data for the bar chart
// //   const chartData = {
// //     labels: raceLabels,
// //     datasets: years.map((year, index) => ({
// //       label: year,
// //       data: data.map((item) => parseFloat(item[year]) || 0),
// //       backgroundColor: `rgba(${75 + index * 30}, ${192 - index * 20}, ${
// //         192 - index * 10
// //       }, 0.5)`,
// //       borderColor: `rgba(${75 + index * 30}, ${192 - index * 20}, ${
// //         192 - index * 10
// //       }, 1)`,
// //       borderWidth: 1,
// //     })),
// //   };

// //   const downloadPDF = () => {
// //     const input = pdfRef.current;
// //     input.style.width = "1100px"; // Adjust width for better PDF rendering

// //     html2canvas(input, { scale: 3 }).then((canvas) => {
// //       const imgData = canvas.toDataURL("image/png");
// //       const pdf = new jsPDF("p", "mm", "a4");
// //       const imgWidth = 210;
// //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

// //       // Add Header
// //       pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
// //       pdf.setFontSize(26);
// //       pdf.text("UTahData.org", 95, 22);
// //       pdf.line(10, 35, 200, 35);

// //       // Add Content
// //       pdf.addImage(imgData, "PNG", 5, 38, imgWidth, imgHeight);

// //       // Add Footer
// //       pdf.line(10, 260, 200, 260);
// //       pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
// //       pdf.setFontSize(10);
// //       pdf.text("Built by", 55, 275);
// //       pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
// //       pdf.text("using", 112, 275);
// //       pdf.text("20250106.v1.85", 148, 275);
// //       const currentDate = new Date().toLocaleDateString();
// //       pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
// //       const currentURL = window.location.href;
// //       pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

// //       pdf.save("employment-by-year-and-race.pdf");
// //       input.style.width = ""; // Reset width
// //     });
// //   };

// //   return (
// //     <div className="container">
// //       <div ref={pdfRef} className="content-to-export">
// //         <h3 className="mt-3">Employment by Year and Race</h3>

// //         {/* Table Section */}
// //         <Table striped bordered hover responsive className="mb-4">
// //           <thead>
// //             <tr>
// //               <th>Race</th>
// //               {years.map((year) => (
// //                 <th key={year}>{year}</th>
// //               ))}
// //             </tr>
// //           </thead>
// //           <tbody>
// //             {data.map((item, index) => (
// //               <tr key={index}>
// //                 <td>{item.race_label}</td>
// //                 {years.map((year) => (
// //                   <td key={year}>{item[year]}</td>
// //                 ))}
// //               </tr>
// //             ))}
// //           </tbody>
// //         </Table>

// //         {/* Chart Section */}
// //         <div
// //           style={{
// //             overflowX: "auto",
// //             display: "flex",
// //             justifyContent: "center",
// //             width: "100%",
// //           }}
// //         >
// //           <div
// //             style={{
// //               minWidth: "600px",
// //               width: "100%",
// //               height: "400px",
// //             }}
// //           >
// //             <Bar
// //               data={chartData}
// //               options={{
// //                 responsive: true,
// //                 plugins: {
// //                   legend: {
// //                     position: "top",
// //                   },
// //                   title: {
// //                     display: true,
// //                     text: "Employment by Year and Race",
// //                   },
// //                 },
// //               }}
// //             />
// //           </div>
// //         </div>
// //       </div>

// //       <div className="text-center mt-4">
// //         <button className="btn btn-primary" onClick={downloadPDF}>
// //           Download PDF
// //         </button>
// //       </div>
// //     </div>
// //   );
// // };

// // export default AeroTable23;

// import React, { useRef } from "react";
// import Table from "react-bootstrap/Table";
// import { Line } from "react-chartjs-2";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

// // Import images for PDF header and footer
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const utahData = "/img/1.png";
// const kpi = "/img/KPI-DASH 2.png";
// const bodex = "/img/bodexLogo.png";
// const pdf = "/img/pdf1.png";

// const AeroTable23 = ({ data }) => {
//   const pdfRef = useRef();

//   // Extract years and prepare chart data
//   const years = ["2018", "2019", "2020", "2021", "2022", "2023"];
//   const chartData = {
//     labels: years,
//     datasets: data.map((item, index) => ({
//       label: item.race_label,
//       data: years.map((year) => item[year]),
//       borderColor: `hsl(${index * 60}, 70%, 50%)`,
//       backgroundColor: `hsla(${index * 60}, 70%, 50%, 0.2)`,
//       tension: 0.4,
//       fill: true,
//     })),
//   };

//   const downloadPDF = () => {
//     const input = pdfRef.current;
//     input.style.width = "1100px";

//     html2canvas(input, { scale: 3 }).then((canvas) => {
//       const imgData = canvas.toDataURL("image/png");
//       const pdf = new jsPDF("p", "mm", "a4");
//       const imgWidth = 210;
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;

//       // Header
//       pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
//       pdf.setFontSize(26);
//       pdf.text("UTahData.org", 95, 22);
//       pdf.line(10, 35, 200, 35);

//       // Main Content
//       pdf.addImage(imgData, "PNG", 5, 38, imgWidth, imgHeight);

//       // Footer
//       pdf.line(10, 260, 200, 260);
//       pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
//       pdf.setFontSize(10);
//       pdf.text("Built by", 55, 275);
//       pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
//       pdf.text("using", 112, 275);
//       pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
//       pdf.text("20250106.v1.85", 148, 275);
//       const currentDate = new Date().toLocaleDateString();
//       pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
//       const currentURL = window.location.href;
//       pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

//       pdf.save("employment-by-year-and-race.pdf");
//       input.style.width = "";
//     });
//   };

//   return (
//     <div className="container">
//       <div ref={pdfRef} className="content-to-export">
//         <h3 className="mt-3">Employment by Year and Race (2018-2023)</h3>

//         {/* Table Section */}
//         <Table striped bordered hover responsive className="mb-4">
//           <thead>
//             <tr>
//               <th>Race</th>
//               {years.map((year) => (
//                 <th key={year}>{year}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {data.map((item, index) => (
//               <tr key={index}>
//                 <td>{item.race_label}</td>
//                 {years.map((year) => (
//                   <td key={year}>{item[year]}</td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </Table>

//         {/* Chart Section */}
//         <div
//           style={{
//             overflowX: "auto",
//             display: "flex",
//             justifyContent: "center",
//             width: "100%",
//           }}
//         >
//           <div
//             style={{
//               minWidth: "600px",
//               width: "1000px",
//               height: "400px",
//             }}
//           >
//             <Line
//               data={chartData}
//               options={{ responsive: true, maintainAspectRatio: false }}
//             />
//           </div>
//         </div>
//       </div>

//       {/* Download PDF Button */}
//       <div className="flex justify-center mt-4">
//         <button
//           onClick={downloadPDF}
//           className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
//         >
//           <span>Download as PDF</span>
//           <span>
//             <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
//           </span>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default AeroTable23;

import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Table } from "react-bootstrap";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const AeroTable23 = ({ data }) => {
  const pdfRef = useRef();
  if (!data.length) return <p>No Data Available</p>;

  // Extracting years dynamically from the data
  const years = Object.keys(data[0]).filter((key) => key.match(/\d{4}/));

  // Generate table headers
  const tableHeaders = ["Race", ...years];

  // Generate chart labels (years)
  const chartLabels = years;

  // Generate datasets for the chart
  const datasets = data.map((item) => ({
    label: item.race_label,
    data: years.map((year) => parseInt(item[year]) || 0),
    backgroundColor: getRandomColor(),
  }));

  // Helper function to generate random colors for datasets
  function getRandomColor() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgba(${r}, ${g}, ${b}, 0.6)`;
  }

  // Chart configuration
  const chartData = {
    labels: chartLabels,
    datasets: datasets,
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Employment By Race and Year",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Year",
        },
        ticks: {
          maxRotation: 45,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Employment Count",
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;

    const originalWidth = input.style.width;
    input.style.width = "1100px";
    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4"); // Portrait mode, A4 size

      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add the header logo
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30); // (x, y, width, height)

      // Add "UTahData.org" text beside the logo
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22); // Position the text next to the logo

      // Add clickable link
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString(); // Format: MM/DD/YYYY or DD/MM/YYYY based on locale
      pdf.setFontSize(12);
      pdf.text(`Downloaded on: ${formattedDate}`, 150, 295); // Fixed backticks

      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(10, 35, 200, 35);

      // Add the main content (adjust position to avoid overlap with header)
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      pdf.line(10, 260, 200, 260);

      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);

      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);

      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);

      pdf.text("20250106.v1.85", 148, footerStartY + 10);

      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295); // Fixed backticks

      pdf.save("dashboard_with_footer.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="dashboard-container">
      <div ref={pdfRef} className="content-to-export">
        <div style={{ padding: "20px" }}>
          <h2>Employment Data by Race and Year (2018-2023)</h2>

          {/* Render Table with Horizontal Scrolling */}
          <div style={{ overflowX: "auto", marginBottom: "20px" }}>
            <Table
              border="1"
              cellPadding="10"
              style={{ width: "100%", minWidth: "600px" }}
            >
              <thead>
                <tr>
                  {tableHeaders.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>{row.race_label}</td>
                    {years.map((year) => (
                      <td key={`${year}-${rowIndex}`}>{row[year]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Bar Chart Section */}

          <div
            style={{
              width: "90%",
              maxWidth: "800px",
              margin: "auto",
              overflowX: "auto",
            }}
          >
            {" "}
            <div
              style={{
                width: "100%", // Takes 100% width of the parent div, but will scroll if needed
                minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
                height: "400px",
              }}
            >
              <Bar data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <span>
            <img className="w-[2rem]" src={pdf} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AeroTable23;
