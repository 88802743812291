// // // // import React from "react";
// // // // import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// // // // import "leaflet/dist/leaflet.css";
// // // // import L from "leaflet";

// // // // // Custom marker icon (optional)
// // // // const icon = new L.Icon({
// // // //   iconUrl: "https://leafletjs.com/examples/custom-icons/leaf-red.png", // Replace with your own icon
// // // //   iconSize: [25, 41],
// // // //   iconAnchor: [12, 41],
// // // //   popupAnchor: [1, -34],
// // // // });
// // // // const geothermalSpots = [
// // // //     { name: "Spot 1", lat: 39.320980, lng: -111.093731 }, // Example data
// // // //     { name: "Spot 2", lat: 40.233845, lng: -112.042845 },
// // // //     { name: "Spot 3", lat: 38.897957, lng: -110.761429 },
// // // //   ];
  
// // // //   const GeothermalMap = () => {
// // // //     return (
// // // //       <div style={{ height: "90vh", width: "100%" }}>
// // // //         <MapContainer center={[39.320980, -111.093731]} zoom={6} style={{ height: "100%", width: "100%" }}>
// // // //           <TileLayer
// // // //             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
// // // //             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
// // // //           />
// // // //           {geothermalSpots.map((spot, index) => (
// // // //             <Marker key={index} position={[spot.lat, spot.lng]} icon={icon}>
// // // //               <Popup>{spot.name}</Popup>
// // // //             </Marker>
// // // //           ))}
// // // //         </MapContainer>
// // // //       </div>
// // // //     );
// // // //   };
  
// // // //   export default GeothermalMap;












// // // import React from "react";
// // // import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// // // import "leaflet/dist/leaflet.css";
// // // import L from "leaflet";
// // // import "../styles/Geothermal.css";
// // // import geothermalData from "./GeoThermalJson.json";


// // // const orange = "/img/orange3.png"
// // // const orange1 = "/img/orange.png"
// // // const spot = "/img/spot.png"

// // // // Custom marker icon
// // // const icon = new L.Icon({
// // //   // iconUrl: "https://leafletjs.com/examples/custom-icons/leaf-red.png",
// // //   iconUrl:spot,
// // //   iconSize: [35, 41],
// // //   iconAnchor: [12, 41],
// // //   popupAnchor: [1, -34],
// // // });

// // // const geothermalSpots = [
// // //   { name: "Spot 1", lat: 39.32098, lng: -111.093731 },
// // //   { name: "Spot 2", lat: 40.233845, lng: -112.042845 },
// // //   { name: "Spot 3", lat: 38.897957, lng: -110.761429 },
// // // ];

// // // const GeothermalMap = () => {
// // //   return (
// // //     <div className="geothermal-map">
// // //       <MapContainer
// // //         center={[39.32098, -111.093731]}
// // //         zoom={7.5}
// // //         minZoom={6}
// // //         maxBounds={[
// // //           [30, -105], // Southwest corner
// // //           [50, -120], // Northeast corner
// // //         ]}
// // //         maxBoundsViscosity={1.0}
// // //         className="map"
// // //       >
// // //         <TileLayer
// // //           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
// // //           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
// // //         />
// // //         {geothermalData
// // //         .filter((spot)=> spot.FLOW)
// // //         .filter((spot)=> spot.DEPTH)
// // //         .map((spot, index) => (
// // //           <Marker key={index} position={[spot.LAT, spot.LONG]} icon={icon}>
// // //             <Popup>
// // //               <b><u>Geothermal Hotspot</u></b><br/>
// // //             Region:{spot.REGION_LOC} <br/>
// // //             County:{spot.COUNTY} <br/>
// // //             Type:{spot.TYPE}<br/>
// // //             Map No.:{spot.MAPNO}<br/>
// // //             Depth:{spot.DEPTH}<br/>
// // //             Class:{spot.CLASS}<br/>
// // //             Reference:{spot.REFERENCE}<br/>
// // //             Source:{spot.SOURCE}<br/>
// // //             Location:{spot.LOCATION}<br/>
// // //             </Popup>
// // //           </Marker>
// // //         ))}
// // //       </MapContainer>
// // //     </div>
// // //   );
// // // };

// // // export default GeothermalMap;

  











// // import React, { useState } from "react";
// // import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
// // import "leaflet/dist/leaflet.css";
// // import L from "leaflet";
// // import "../styles/Geothermal.css";
// // import geothermalData from "./GeoThermalJson.json";

// // const spot = "/img/spot.png";

// // // Custom marker icon
// // const icon = new L.Icon({
// //   iconUrl: spot,
// //   iconSize: [35, 41],
// //   iconAnchor: [12, 41],
// //   popupAnchor: [1, -34],
// // });

// // // Helper component to pan the map
// // const MapFocus = ({ location }) => {
// //   const map = useMap();
// //   if (location) {
// //     map.flyTo(location, 10); // Fly to the selected location with zoom level 10
// //   }
// //   return null;
// // };

// // const GeothermalMap = () => {
// //   const [selectedLocation, setSelectedLocation] = useState(null);

// //   const handleLocationClick = (location) => {
// //     setSelectedLocation(location); // Update selected location
// //   };

// //   return (
// //     <div className="geothermal-map" style={{ display: "flex" }}>
// //       {/* Left-side list */}
// //       <div
// //         style={{
// //           width: "30%",
// //           padding: "10px",
// //           backgroundColor: "#f9f9f9",
// //           borderRight: "1px solid #ddd",
// //           overflowY: "auto",
// //           background: "#d2eaff",
// //         }}
// //       >
// //         <h2>Geothermal Hotspots</h2>
// //         <ul style={{ listStyle: "none", padding: 0 }}>
// //           {geothermalData.map((spot, index) => (
// //             <li
// //               key={index}
// //               style={{
// //                 padding: "10px",
// //                 margin: "5px 0",
// //                 cursor: "pointer",
// //                 backgroundColor: "#e6f7ff",
// //                 border: "1px solid #ddd",
// //                 borderRadius: "5px",
// //               }}
// //               onClick={() =>
// //                 handleLocationClick({
// //                   lat: spot.LAT,
// //                   lng: spot.LONG,
// //                   details: spot,
// //                 })
// //               }
// //             >
// //               {spot.LOCATION || `Hotspot ${index + 1}`}
// //             </li>
// //           ))}
// //         </ul>
// //       </div>

// //       {/* Map */}
// //       <div style={{ width: "70%" }}>
// //         <MapContainer
// //           center={[39.32098, -111.093731]}
// //           zoom={7.5}
// //           minZoom={6}
// //           maxBounds={[
// //             [30, -105], // Southwest corner
// //             [50, -120], // Northeast corner
// //           ]}
// //           maxBoundsViscosity={1.0}
// //           className="map"
// //         >
// //           <TileLayer
// //             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
// //             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
// //           />
// //           {selectedLocation && (
// //             <MapFocus location={[selectedLocation.lat, selectedLocation.lng]} />
// //           )}
// //           {geothermalData.map((spot, index) => (
// //             <Marker key={index} position={[spot.LAT, spot.LONG]} icon={icon}>
// //               <Popup>
// //                 <b>
// //                   <u>Geothermal Hotspot</u>
// //                 </b>
// //                 <br />
// //                 Region: {spot.REGION_LOC} <br />
// //                 County: {spot.COUNTY} <br />
// //                 Type: {spot.TYPE}
// //                 <br />
// //                 Map No.: {spot.MAPNO}
// //                 <br />
// //                 Depth: {spot.DEPTH}
// //                 <br />
// //                 Class: {spot.CLASS}
// //                 <br />
// //                 Reference: {spot.REFERENCE}
// //                 <br />
// //                 Source: {spot.SOURCE}
// //                 <br />
// //                 Location: {spot.LOCATION}
// //                 <br />
// //               </Popup>
// //             </Marker>
// //           ))}
// //         </MapContainer>
// //       </div>
// //     </div>
// //   );
// // };

// // export default GeothermalMap;




















// import React, { useState, useRef } from "react";
// import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// import "../styles/Geothermal.css";
// import geothermalData from "./GeoThermalJson.json";

// const spot = "/img/spot.png";

// // Custom marker icon
// const icon = new L.Icon({
//   iconUrl: spot,
//   iconSize: [35, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
// });

// // Helper component to pan the map
// const MapFocus = ({ location }) => {
//   const map = useMap();
//   if (location) {
//     map.flyTo(location, 10); // Fly to the selected location with zoom level 10
//   }
//   return null;
// };

// const GeothermalMap = () => {
//   const [selectedLocation, setSelectedLocation] = useState(null);
//   const markerRefs = useRef([]); // Create refs for all markers

//   const handleLocationClick = (location, index) => {
//     setSelectedLocation(location); // Update selected location

//     // Open the corresponding popup
//     if (markerRefs.current[index]) {
//       markerRefs.current[index].openPopup();
//     }
//   };

//   return (
//     <div className="geothermal-map" style={{ display: "flex" }}>
//       {/* Left-side list */}
//       <div
//         style={{
//           width: "30%",
//           padding: "10px",
//           backgroundColor: "#f9f9f9",
//           borderRight: "1px solid #ddd",
//           overflowY: "auto",
//           background: "#d2eaff",
//           marginTop: "13vh"
//         }}
//       >
//         <h2>Geothermal Hotspots</h2>
//         <ul style={{ listStyle: "none", padding: 0 }}>
//           {geothermalData
//           .filter((spot)=> spot.DEPTH)
//           .filter((spot)=> spot.FLOW)
//           .map((spot, index) => (
//             <li
//               key={index}
//               style={{
//                 padding: "10px",
//                 margin: "5px 0",
//                 cursor: "pointer",
//                 backgroundColor: "#e6f7ff",
//                 border: "1px solid #ddd",
//                 borderRadius: "5px",
//               }}
//               onClick={() =>
//                 handleLocationClick(
//                   { lat: spot.LAT, lng: spot.LONG, details: spot },
//                   index
//                 )
//               }
//             >
             

// <div style={{ display: "flex", gap: "50px" }}>
//   <span>{spot.REGION_LOC || `Hotspot ${index + 1}`}</span>
//   <span>{spot.MAPNO}</span>
// </div>



//             </li>
//           ))}
//         </ul>
//       </div>

//       {/* Map */}
//       <div style={{ width: "70%" }}>
//         <MapContainer
//           center={[39.32098, -111.093731]}
//           zoom={7.5}
//           minZoom={6}
//           maxBounds={[
//             [30, -105], // Southwest corner
//             [50, -120], // Northeast corner
//           ]}
//           maxBoundsViscosity={1.0}
//           className="map"
//         >
//           <TileLayer
//             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//           />
//           {selectedLocation && (
//             <MapFocus location={[selectedLocation.lat, selectedLocation.lng]} />
//           )}
//           {geothermalData
//             .filter((spot)=> spot.DEPTH)
//             .filter((spot)=> spot.FLOW)
//           .map((spot, index) => (
//             <Marker
//               key={index}
//               position={[spot.LAT, spot.LONG]}
//               icon={icon}
//               ref={(el) => (markerRefs.current[index] = el)} // Save reference to marker
//             >
//               <Popup>
//                 <b>
//                   <u>Geothermal Hotspot</u>
//                 </b>
//                 <br />
//                 Region: {spot.REGION_LOC} <br />
//                 County: {spot.COUNTY} <br />
//                 Type: {spot.TYPE}
//                 <br />
//                 Map No.: {spot.MAPNO}
//                 <br />
//                 Depth: {spot.DEPTH}
//                 <br />
//                 Class: {spot.CLASS}
//                 <br />
//                 Reference: {spot.REFERENCE}
//                 <br />
//                 Source: {spot.SOURCE}
//                 <br />
//                 Location: {spot.LOCATION}
//                 <br />
//               </Popup>
//             </Marker>
//           ))}
//         </MapContainer>
//       </div>
//     </div>
//   );
// };

// export default GeothermalMap;










import React, { useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "../styles/Geothermal.css";
import geothermalData from "./GeoThermalJson.json";

const spot = "/img/location1.png";



// Utility to detect mobile view
const isMobileView = () => window.innerWidth <= 778; // Adjust breakpoint as needed

// Custom marker icon with dynamic popup anchor
const createIcon = () =>
  new L.Icon({
    iconUrl: spot,
    iconSize: [35, 41],
    iconAnchor: [12, 41],
    popupAnchor: isMobileView() ? [0, 10] : [1, -34], // Below marker for mobile, above for others
  });


// Custom marker icon
const icon = new L.Icon({
  iconUrl: spot,
  iconSize: [35, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

// Helper component to pan the map
const MapFocus = ({ location }) => {
  const map = useMap();
  if (location && window.innerWidth <= 778) {
    map.flyTo(location, 5); // Fly to the selected location with zoom level 10
  }
  if (location && window.innerWidth > 778) {
    map.flyTo(location, 10); // Fly to the selected location with zoom level 10
  }
  return null;
};

const GeothermalMap = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const markerRefs = useRef([]); // Create refs for all markers

  const handleLocationClick = (location, index) => {
    setSelectedLocation(location); // Update selected location

    // Open the corresponding popup
    if (markerRefs.current[index]) {
      markerRefs.current[index].openPopup();
    }
  };

  return (
    <div className="geothermal-map">
      {/* Left-side list */}
      <div className="list-container">
        <h2>Geothermal Hotspots <span className="text-sm font-base">(*Not all hotspots are marked, only those with fully verified datas are displayed.)</span></h2>
        <h3>
        <div className="list-item-content">
        <span>Map No.</span>
        <span>County</span>
        <span>Region</span>
        <span>Source</span>
        </div>
        </h3>
        <ul>
          {geothermalData
            .filter((spot) => spot.DEPTH)
            .filter((spot) => spot.FLOW)
            .map((spot, index) => (
              <li
                key={index}
                className="list-item"
                onClick={() =>
                  handleLocationClick(
                    { lat: spot.LAT, lng: spot.LONG, details: spot },
                    index
                  )
                }
              >
                <div className="list-item-content">
                <span>{spot.MAPNO}</span>
                <span>{spot.COUNTY}</span>
                  <span>{spot.REGION_LOC || `Hotspot ${index + 1}`}</span>
                  <span>{spot.SOURCE}</span>
                 
                </div>
              </li>
            ))}
        </ul>
      </div>

      {/* Map */}
      <div className="map-container ">
        <MapContainer
          center={[39.32098, -111.093731]}
          zoom={7}
          minZoom={6}
          maxBounds={[
            [30, -105], // Southwest corner
            [50, -120], // Northeast corner
          ]}
          maxBoundsViscosity={1.0}
          className="map"
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {selectedLocation && (
            <MapFocus location={[selectedLocation.lat, selectedLocation.lng]} />
          )}
          {geothermalData
            .filter((spot) => spot.DEPTH)
            .filter((spot) => spot.FLOW)
            .map((spot, index) => (
              <Marker
                key={index}
                position={[spot.LAT, spot.LONG]}
                // icon={icon}
                // ref={(el) => (markerRefs.current[index] = el)} // Save reference to marker
                icon={createIcon()} // Use dynamic icon here
                ref={(el) => (markerRefs.current[index] = el)}
              >
                <Popup>
                  <b>
                    <u>Geothermal Hotspot</u>
                  </b>
                  <br />
                  Region: {spot.REGION_LOC} <br />
                  County: {spot.COUNTY} <br />
                  Type: {spot.TYPE}
                  <br />
                  Map No.: {spot.MAPNO}
                  <br />
                  Depth: {spot.DEPTH}
                  <br />
                  Class: {spot.CLASS}
                  <br />
                  Reference: {spot.REFERENCE}
                  <br />
                  Source: {spot.SOURCE}
                  <br />
                  Location: {spot.LOCATION}
                  <br />
                </Popup>
              </Marker>
            ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default GeothermalMap;
