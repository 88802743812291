import React from 'react'


const UniversityofUtah = "/university/University-of-Utah-Logo.jpg"
const UtahStateUniversity = "/university/Utah State University.png"
const southernUtahUniversity = "/university/Southern Utah University.png"
const USUBrigham = "/university/USU Brigham.png"
const USUEastern = "/university/USU Eastern.png"
const USUToole = "/university/USU Tooele.jpeg"
const USUunnitah = "/university/USU Uintah Basin.png"
const UtahTech = "/university/Utah Tech University.png"
const UtahValley = "/university/Utah Valley University.png"
const weber = "/university/Weber State University.png" 
const saltLake = "/university/Salt Lake College.png"
const snowCollege = "/university/Snow College.png"
const bridgerland = "/university/Bridgerland Tech College.png"
const davis = "/university/Davis Tech College.png"
const Dixie = "/university/Dixie Tech College.png"
const Mountainland = "/university/Mountainland Tech College.png"
const brigham = "/university/Brigham Young University.png"
const broadview = "/university/Broadview University.png"
const eaglegatecollege = "/university/Eagle gate College.png"
const ensign = "/university/Ensign College.png"
const fortis = "/university/Fortis College.png"
const neumont = "/university/Neumont College.png"
const nightingale = "/university/Nightingale.png"
const OgdenWeber = "/university/Ogden Weber Tech College.png"
const provocollege = "/university/Provo College.png"
const Rocky = "/university/Rocky Mountain University.png"
const roseman = "/university/Roseman University.png"
const Southwest = "/university/SouthWest Tech College.jpeg"
const Stevens = "/university/Stevens Henager College.png"
const tooele = "/university/Tooele Tech College.jpeg"
const Uintah = "/university/Uintah Basin Tech College.png"
const phoenix = "/university/University of Phoenix.png"
const webster = "/university/Webster University.png"
const Western = "/university/Western Governors University.png"
const westminstercollege = "/university/Westminster University.png"
const rockyVista = "/university/Rocky Vista University.png"







export default function UtahUniversities() {
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
        <h1>Utah Higher Education Institutions</h1>
        <div className='mt-2 font-bold text-2xl'>(36 Universities)</div>
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto w-full border-collapse border border-gray-300 bg-white">
    <thead>
      <tr className="bg-gray-200">
        <th className="border border-gray-300 px-4 py-2 text-left">Category</th>
        <th className="border border-gray-300 px-4 py-2 text-left">Institution</th>
        <th className="border border-gray-300 px-4 py-2 text-left">Website</th>
      </tr>
    </thead>
    <tbody>
      {/* Public Research Universities */}
      <tr className="hover:bg-gray-100">
        <td className="border border-gray-300 px-4 py-2 font-bold" rowSpan="2">Public Research Universities</td>
        <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={UniversityofUtah} className='w-[6rem] h-auto mx-[3rem]'></img>University of Utah</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.utah.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.utah.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center  items-center"><img src={UtahStateUniversity} className='w-[6rem] h-auto mx-[3rem]'></img>Utah State University</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.usu.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.usu.edu</a>
        </td>
      </tr>

      {/* Public Regional Universities */}
      <tr className="hover:bg-gray-100">
        <td className="border border-gray-300 px-4 py-2 font-bold" rowSpan="8">Public Regional Universities</td>
        <td className="border border-gray-300 px-4 py-2 flex text-center  items-center"><img src={southernUtahUniversity} className='w-[6rem] h-auto mx-[3rem]'></img>Southern Utah University</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.suu.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.suu.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={USUBrigham} className='w-[6rem] h-auto mx-[3rem]'></img>USU-Brigham City</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.statewide.usu.edu/brighamcity" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.statewide.usu.edu/brighamcity</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={USUEastern} className='w-[6rem] h-auto mx-[3rem]'></img>USU-Eastern</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.eastern.usu.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.eastern.usu.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={USUToole} className='w-[6rem] h-auto mx-[3rem]'></img>USU-Tooele</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.statewide.usu.edu/tooele" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.statewide.usu.edu/tooele</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={USUunnitah} className='w-[6rem] h-auto mx-[3rem]'></img>USU-Uintah Basin</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.statewide.usu.edu/uintahbasin" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.statewide.usu.edu/uintahbasin</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={UtahTech} className='w-[6rem] h-auto mx-[3rem]'></img>Utah Tech University</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.utahtech.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.utahtech.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={UtahValley} className='w-[6rem] h-auto mx-[3rem]'></img>Utah Valley University</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.uvu.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.uvu.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={weber} className='w-[6rem] h-auto mx-[3rem]'></img>Weber State University</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.weber.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.weber.edu</a>
        </td>
      </tr>

      {/* Comprehensive Community Colleges */}
      <tr className="hover:bg-gray-100">
        <td className="border border-gray-300 px-4 py-2 font-bold" rowSpan="2">Comprehensive Community Colleges</td>
        <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={saltLake} className='w-[6rem] h-auto mx-[3rem]'></img>Salt Lake Community College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.slcc.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.slcc.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={snowCollege} className='w-[6rem] h-auto mx-[3rem]'></img>Snow College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.snow.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.snow.edu</a>
        </td>
      </tr>


            {/* Technical Colleges */}
            <tr className="hover:bg-gray-100">
        <td className="border border-gray-300 px-4 py-2 font-bold" rowSpan="8">Technical Colleges</td>
        <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={bridgerland} className='w-[6rem] h-auto mx-[3rem]'></img>Bridgerland Technical College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.btech.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.btech.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={davis} className='w-[6rem] h-auto mx-[3rem]'></img>Davis Technical College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.davistech.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.davistech.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={Dixie} className='w-[6rem] h-auto mx-[3rem]'></img>Dixie Technical College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.dixietech.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.dixietech.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={Mountainland} className='w-[6rem] h-auto mx-[3rem]'></img>Mountainland Technical College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.mtec.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.mtec.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={OgdenWeber} className='w-[6rem] h-auto mx-[3rem]'></img>Ogden–Weber Technical College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.otech.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.otech.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={Southwest} className='w-[6rem] h-auto mx-[3rem]'></img>Southwest Technical College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.stech.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.stech.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={tooele} className='w-[6rem] h-auto mx-[3rem]'></img>Tooele Technical College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.tooeletech.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.tooeletech.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={Uintah} className='w-[6rem] h-auto mx-[3rem]'></img>Uintah Basin Technical College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.ubtech.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.ubtech.edu</a>
        </td>
      </tr>

      {/* Private Non-Profit Institutions */}
      <tr className="hover:bg-gray-100">
        <td className="border border-gray-300 px-4 py-2 font-bold" rowSpan="4">Private Non-Profit Institutions</td>
        <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={brigham} className='w-[6rem] h-auto mx-[3rem]'></img>Brigham Young University</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.byu.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.byu.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={ensign} className='w-[6rem] h-auto mx-[3rem]'></img>Ensign College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.ensign.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.ensign.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={Western} className='w-[6rem] h-auto mx-[3rem]'></img>Western Governors University</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.wgu.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.wgu.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={westminstercollege} className='w-[6rem] h-auto mx-[3rem]'></img>Westminster University</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.westminstercollege.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.westminstercollege.edu</a>
        </td>
      </tr>

      {/* Private For-Profit Institutions */}
      <tr className="hover:bg-gray-100">
        <td className="border border-gray-300 px-4 py-2 font-bold" rowSpan="12">Private For-Profit Institutions</td>
        <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={broadview} className='w-[6rem] h-auto mx-[3rem]'></img>Broadview University</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.broadviewuniversity.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.broadviewuniversity.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={eaglegatecollege} className='w-[6rem] h-auto mx-[3rem]'></img>Eagle Gate College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.eaglegatecollege.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.eaglegatecollege.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={fortis} className='w-[6rem] h-auto mx-[3rem]'></img>Fortis College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.fortis.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.fortis.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={neumont} className='w-[6rem] h-auto mx-[3rem]'></img>Neumont College of Computer Science</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.neumont.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.neumont.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={nightingale} className='w-[6rem] h-auto mx-[3rem]'></img>Nightingale College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.nightingale.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.nightingale.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={provocollege} className='w-[6rem] h-auto mx-[3rem]'></img>Provo College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.provocollege.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.provocollege.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={Rocky} className='w-[6rem] h-auto mx-[3rem]'></img>Rocky Mountain University of Health Professions</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.rm.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.rm.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={rockyVista} className='w-[6rem] h-auto mx-[3rem]'></img>Rocky Vista University</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.rvu.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.rvu.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={roseman} className='w-[6rem] h-auto mx-[3rem]'></img>Roseman University of Health Sciences</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.roseman.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.roseman.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={Stevens} className='w-[6rem] h-auto mx-[3rem]'></img>Stevens-Henager College</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.stevenshenager.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.stevenshenager.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={phoenix} className='w-[6rem] h-auto mx-[3rem]'></img>University of Phoenix</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.phoenix.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.phoenix.edu</a>
        </td>
      </tr>
      <tr className="hover:bg-gray-100">
      <td className="border border-gray-300 px-4 py-2 flex text-center items-center"><img src={webster} className='w-[6rem] h-auto mx-[3rem]'></img>Webster University</td>
        <td className="border border-gray-300 px-4 py-2">
          <a href="https://www.webster.edu" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">www.webster.edu</a>
        </td>
      </tr>

    </tbody>
  </table>
</div>

    </div>
    </div>
  )
}
