// // // import React from 'react';
// // // import 'bootstrap/dist/css/bootstrap.min.css';
// // // import { Table } from 'react-bootstrap';
// // // import { Bar } from 'react-chartjs-2';
// // // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// // // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // // export const TableNo26 = () => {
// // //   const femaleData = [
// // //     { ageGroup: '14-18', femaleShare: 2.43 },
// // //     { ageGroup: '19-21', femaleShare: 4.88 },
// // //     { ageGroup: '22-24', femaleShare: 6.42 },
// // //     { ageGroup: '25-34', femaleShare: 20.93 },
// // //     { ageGroup: '35-44', femaleShare: 21.56 },
// // //     { ageGroup: '45-54', femaleShare: 21.50 },
// // //     { ageGroup: '55-64', femaleShare: 16.02 },
// // //     { ageGroup: '65-99', femaleShare: 6.24 },
// // //   ];

// // //   const chartData = {
// // //     labels: femaleData.map(item => item.ageGroup),
// // //     datasets: [
// // //       {
// // //         label: 'Female Share %',
// // //         data: femaleData.map(item => item.femaleShare),
// // //         backgroundColor: 'rgba(255, 99, 132, 0.6)',
// // //         borderColor: 'rgba(255, 99, 132, 1)',
// // //         borderWidth: 1,
// // //       },
// // //     ],
// // //   };

// // //   const options = {
// // //     responsive: true,
// // //     maintainAspectRatio: false,
// // //     scales: {
// // //       y: {
// // //         beginAtZero: true,
// // //         ticks: {
// // //           callback: function(value) {
// // //             return value + '%'; // Adding percentage sign
// // //           },
// // //         },
// // //       },
// // //     },
// // //   };

// // //   return (
// // //     <div className="container">
// // //       <h2>Female Share Percentage Analysis</h2>

// // //       {/* Responsive Table */}
// // //       <Table striped bordered hover responsive>
// // //         <thead>
// // //           <tr>
// // //             <th>Age Group</th>
// // //             <th>Female Share %</th>
// // //           </tr>
// // //         </thead>
// // //         <tbody>
// // //           {femaleData.map((item, index) => (
// // //             <tr key={index}>
// // //             <>
// // //               <td>{item.ageGroup}</td>
// // //               <td>{item.femaleShare.toFixed(2)}%</td> {/* Displaying with two decimal places */}
// // //               </>
// // //             </tr>
// // //           ))}
// // //         </tbody>
// // //       </Table>

// // //       {/* Bar Chart */}
// // //       <div style={{ height: '400px', width: '100%' }}>
// // //         <Bar data={chartData} options={options} />
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // import React from 'react';
// // import 'bootstrap/dist/css/bootstrap.min.css';
// // import { Table } from 'react-bootstrap';
// // import { Bar } from 'react-chartjs-2';
// // import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// // export const TableNo26 = () => {
// //   const femaleData = [
// //     { ageGroup: '14-18', femaleShare: 2.43 },
// //     { ageGroup: '19-21', femaleShare: 4.88 },
// //     { ageGroup: '22-24', femaleShare: 6.42 },
// //     { ageGroup: '25-34', femaleShare: 20.93 },
// //     { ageGroup: '35-44', femaleShare: 21.56 },
// //     { ageGroup: '45-54', femaleShare: 21.50 },
// //     { ageGroup: '55-64', femaleShare: 16.02 },
// //     { ageGroup: '65-99', femaleShare: 6.24 },
// //   ];

// //   const chartData = {
// //     labels: femaleData.map(item => item.ageGroup),
// //     datasets: [
// //       {
// //         label: 'Female Share %',
// //         data: femaleData.map(item => item.femaleShare),
// //         backgroundColor: 'rgba(255, 99, 132, 0.6)',
// //         borderColor: 'rgba(255, 99, 132, 1)',
// //         borderWidth: 1,
// //       },
// //     ],
// //   };

// //   const options = {
// //     responsive: true,
// //     maintainAspectRatio: false,
// //     scales: {
// //       y: {
// //         beginAtZero: true,
// //         ticks: {
// //           callback: function(value) {
// //             return value + '%'; // Adding percentage sign
// //           },
// //         },
// //       },
// //     },
// //   };

// //   return (
// //     <div className="container">
// //       <h2>Female Share Percentage Analysis</h2>

// //       {/* Responsive Table */}
// //       <Table striped bordered hover responsive>
// //         <thead>
// //           <tr>
// //             <th>Age Group</th>
// //             <th>Female Share %</th>
// //           </tr>
// //         </thead>
// //         <tbody>
// //           {femaleData.map((item, index) => (
// //             <tr key={index}>
// //               <td>{item.ageGroup}</td>
// //               <td>{item.femaleShare.toFixed(2)}%</td>
// //             </tr>
// //           ))}
// //         </tbody>
// //       </Table>

// //       {/* Bar Chart */}
// //       <div
// //           style={{
// //             overflowX: "auto", // Enables horizontal scroll
// //             display: "flex",
// //             justifyContent: "center",
// //             width: "100%", // Takes full width
// //           }}
// //         >
// //           <div
// //             style={{
// //               minWidth: "600px", // Minimum width to enable scroll
// //               width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
// //               height: "400px",
// //             }}
// //           >
// //         <Bar data={chartData} options={options} />
// //       </div>
// //     </div>
// //     </div>
// //   );
// // };

// import React, { useRef } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

// // Images for PDF branding
// const utahData = "/img/1.png";
// const bodex = "/img/bodexLogo.png";
// const kpi = "/img/KPI-DASH 2.png";
// const pdfIcon = "/img/pdf1.png";

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// export const TableNo26 = () => {
//   const pdfRef = useRef();

//   const femaleData = [
//     { ageGroup: '14-18', femaleShare: 2.43 },
//     { ageGroup: '19-21', femaleShare: 4.88 },
//     { ageGroup: '22-24', femaleShare: 6.42 },
//     { ageGroup: '25-34', femaleShare: 20.93 },
//     { ageGroup: '35-44', femaleShare: 21.56 },
//     { ageGroup: '45-54', femaleShare: 21.50 },
//     { ageGroup: '55-64', femaleShare: 16.02 },
//     { ageGroup: '65-99', femaleShare: 6.24 },
//   ];

//   const chartData = {
//     labels: femaleData.map(item => item.ageGroup),
//     datasets: [
//       {
//         label: 'Female Share %',
//         data: femaleData.map(item => item.femaleShare),
//         backgroundColor: 'rgba(255, 99, 132, 0.6)',
//         borderColor: 'rgba(255, 99, 132, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       y: {
//         beginAtZero: true,
//         ticks: {
//           callback: function(value) {
//             return value + '%'; // Adding percentage sign
//           },
//         },
//       },
//     },
//   };

//   const downloadPDF = () => {
//     const input = pdfRef.current;
//     const originalWidth = input.style.width;
//     input.style.width = "1100px"; // Adjust width for better scaling in PDF

//     html2canvas(input, { scale: 3 }).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF('p', 'mm', 'a4');

//       const imgWidth = 210;
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;

//       // Add header branding
//       pdf.addImage(utahData, 'PNG', 60, 4, 30, 30);
//       pdf.setFontSize(26);
//       pdf.text('UTahData.org', 95, 22);
//       pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

//       // Add chart content
//       const headerHeight = 38;
//       pdf.addImage(imgData, 'PNG', 0, headerHeight, imgWidth, imgHeight);

//       // Footer branding and metadata
//       pdf.setLineWidth(0.5);
//       pdf.line(10, 260, 200, 260);
//       pdf.addImage(utahData, 'PNG', 33, 265, 20, 20);
//       pdf.setFontSize(10);
//       pdf.setTextColor(0, 122, 255);
//       pdf.text('Built by', 55, 275);
//       pdf.addImage(bodex, 'PNG', 68, 269, 44, 10);
//       pdf.text('using', 112, 275);
//       pdf.addImage(kpi, 'PNG', 125, 265, 20, 20);
//       pdf.text('20250106.v1.85', 148, 275);
//       pdf.line(10, 35, 200, 35);

//       const currentURL = window.location.href;
//       const currentDate = new Date().toLocaleDateString();
//       pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
//       pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

//       // Save PDF
//       pdf.save('female_share_percentage.pdf');
//       input.style.width = originalWidth;
//     });
//   };

//   return (
//     <div className="container my-4">
//       <div ref={pdfRef}>
//         <h2>Female Share Percentage Analysis</h2>

//         {/* Responsive Table */}
//         <Table striped bordered hover responsive>
//           <thead>
//             <tr>
//               <th>Age Group</th>
//               <th>Female Share %</th>
//             </tr>
//           </thead>
//           <tbody>
//             {femaleData.map((item, index) => (
//               <tr key={index}>
//                 <td>{item.ageGroup}</td>
//                 <td>{item.femaleShare.toFixed(2)}%</td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>

//         {/* Bar Chart */}
//         <div
//           style={{
//             overflowX: "auto",
//             display: "flex",
//             justifyContent: "center",
//             width: "100%",
//           }}
//         >
//           <div
//             style={{
//               minWidth: "600px",
//               width: "1000px",
//               height: "400px",
//             }}
//           >
//             <Bar data={chartData} options={options} />
//           </div>
//         </div>
//       </div>

//       {/* PDF Download Button */}
//       <div className="text-center mt-4">
//       <button
//                     onClick={downloadPDF}
//                     className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
//                 >
//                     <span>Download as PDF</span>
//                     <img className="w-[2rem]" src={pdfIcon} alt="PDF Icon" />
//                 </button>
//       </div>
//     </div>
//   );
// };

import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// Images for PDF branding
const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";
const pdfIcon = "/img/pdf1.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const TableNo26 = () => {
  const pdfRef = useRef();

  const femaleData = [
    { ageGroup: "14-18", femaleShare: 2.43 },
    { ageGroup: "19-21", femaleShare: 4.88 },
    { ageGroup: "22-24", femaleShare: 6.42 },
    { ageGroup: "25-34", femaleShare: 20.93 },
    { ageGroup: "35-44", femaleShare: 21.56 },
    { ageGroup: "45-54", femaleShare: 21.5 },
    { ageGroup: "55-64", femaleShare: 16.02 },
    { ageGroup: "65-99", femaleShare: 6.24 },
  ];

  const chartData = {
    labels: femaleData.map((item) => item.ageGroup),
    datasets: [
      {
        label: "Female Share %",
        data: femaleData.map((item) => item.femaleShare),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + "%"; // Adding percentage sign
          },
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px"; // Adjust width for better scaling in PDF

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header branding
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      // Add chart content
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      // Footer branding and metadata
      pdf.setLineWidth(0.5);
      pdf.line(10, 260, 200, 260);
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
      pdf.text("20250106.v1.85", 148, 275);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

      pdf.save("female_share_percentage.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="container my-4">
      <div ref={pdfRef}>
        <h2>Female Share Percentage Analysis</h2>

        {/* Responsive Table */}
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Age Group</th>
              <th>Female Share %</th>
            </tr>
          </thead>
          <tbody>
            {femaleData.map((item, index) => (
              <tr key={index}>
                <td>{item.ageGroup}</td>
                <td>{item.femaleShare.toFixed(2)}%</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Bar Chart */}
        {/* <div
          style={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              minWidth: "600px",
              width: "1000px",
              height: "400px",
            }}
          > */}

        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
              height: "400px",
            }}
          >
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>

      {/* PDF Download Button */}
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
        </button>
      </div>
    </div>
  );
};
