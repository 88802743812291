








import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";

// List of 21 unique images with titles and descriptions
const images = [
  { url: "/img/glance-agriculture-hunting.jpg", title: "Agriculture, Forestry, Fishing & Hunting", description: "   Establishments : 615 \n Average Employment : 6235  " },
  { url: "/img/glance-mining.jpg", title: "Mining", description: "   Establishments : 597 \nAverage Employment : 11422   " },
  { url: "/img/glance-utilities.webp", title: "Utilities", description: "    Establishments : 497 \n Average Employment : 6907  "  },
  { url: "/img/glance-construction.jpeg", title: "Construction", description: "    Establishments : 15548 \n Average Employment : 139891  " },
  { url: "/img/glance-manufacturing.webp", title: "Manufacturing (31-33)", description: "   Establishments : 5726  \n Average Employment : 152630   "  },
  { url: "/img/glance-wholesale.jpg", title: "Wholesale Trade", description: "   Establishments : 7039 \n Average Employment : 62114  " },
  { url: "/img/glance-retail.jpeg", title: "Retail Trade (44 & 45)", description: "   Establishments : 11175  \n Average Employment : 179494  "  },
  { url: "/img/glance-transportation.jpg", title: "Transportation and Warehousing (48 & 49)", description: "  Establishments : 3681 \n Average Employment : 81642  " },
  { url: "/img/glance-it.jpg", title: "Information", description: "  Establishments : 5076  \n Average Employment : 42959   "  },
  { url: "/img/glance-finance-insurance.jpg", title: "Finance and Insurance", description: "   Establishments : 7828  \n Average Employment : 74730  "  },
  { url: "/img/glance-real-estate-rental.webp", title: "Real Estate and Rental and Leasing", description: "  Establishments : 6562  \n Average Employment : 24509   " },
  { url: "/img/glance-scientific-technical.jpg", title: "Professional, Scientific & Technical Svc", description: "    Establishments : 26883 \n Average Employment : 131629  " },
  { url: "/img/glance-manage-company.jpg", title: "Management of Companies and Enterprises", description: "    Establishments : 1484  \n Average Employment : 22895  " },
  { url: "/img/glance-waste-management.webp", title: "Admin., Support, Waste Mgmt, Remediation", description: "    Establishments : 8991 \n Average Employment : 95114  " },
  { url: "/img/glance-education-service.avif", title: "Education Services", description: "  Establishments : 3508  \n Average Employment : 183714   " },
  { url: "/img/glance-health-service.jpg", title: "Health Care and Social Assistance", description: "    Establishments : 13750 \n Average Employment : 210205  "  },
  { url: "/img/glance-arts-recreation.jpg", title: "Arts, Entertainment, and Recreation", description: "  Establishments : 2037 \n Average Employment : 40990   " },
  { url: "/img/glance-food-service.jpg", title: "Accommodation and Food Services", description: "    Establishments : 7188  \n Average Employment : 145272  " },
  { url: "/img/glance-other-service.webp", title: "Other Services (except Public Admin.)", description: "   Establishments : 8456  \n Average Employment : 48425  " },
  { url: "/img/glance-public-administration.jpg", title: "Public Administration", description: "    Establishments : 1988  \n Average Employment : 91863  " },
  { url: "/img/glance-unclassified.jpg", title: "Unclassified establishments", description: "  Establishments : 98  \n Average Employment : 76  " },
];


const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 300,
  flex: "1 1 calc(30% - 34px)", // Default width for larger screens
  margin: "44px", // Default margin
  padding: "8px",
  [theme.breakpoints.down("md")]: {
    flex: "1 1 calc(45% - 34px)", // Slightly wider cards for medium screens
  },
  [theme.breakpoints.down("sm")]: {
    flex: "1 1 calc(90% - 20px)", // Increase width for mobile screens
    margin: "20px auto", // Center cards and reduce margin
  },
  "&:hover .description": {
    opacity: 1,
    transform: "translateY(0)",
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    boxShadow: "0 0 8px rgba(255, 255, 255, 0.8)", 
    transform: "scale(0.95)", 
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,

    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.8)", 
      backgroundColor: "rgba(0, 0, 0, 0.5)", 
    },
  },
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: "16px",
  right: "16px",
  top: "16px",
  bottom: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));



const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});


const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

export default function ResponsiveCardFlex() {
  return (
    <div className="bg-[#d2eaff]  p-[2rem]">
      <h2 className="text-5xl font-bold text-center mt-[10rem] mb-6">Industries in Utah</h2>
      <div className="font-bold text-3xl text-center">(21 Industries 2024)</div>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          padding: "26px",
        }}
      >
        {images.map((image) => (
          <ImageButton
            focusRipple
            key={image.title}
            style={{
              background: `url(${image.url}) center/cover no-repeat`,
            }}
          >
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
              <Typography
                component="span"
                variant="h5"
                color="inherit"
                sx={{
                  position: "relative",
                  padding: "8px 16px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {image.title}
                {/* <Typography
                  className="description !border-none"
                  sx={{
                    fontSize: "20px",
                    color: "#fad7a0",
                    marginTop: "8px",
                    whiteSpace: "pre-line", 
                    opacity: 0,
                    transition: "opacity 0.3s, transform 0.3s",
                    transform: "translateY(10px)",
                    border: "none",
                    fontWeight: "bold",
                  
                  }}
                >
                  {image.description}
                </Typography>
                */}


                <Typography
  className="description !border-none"
  sx={{
    fontSize: "18px", // Slightly larger font size
    color: "#FFAA1D", // High contrast white color
    marginTop: "8px",
    whiteSpace: "pre-line",
    opacity: 0,
    transition: "opacity 0.3s, transform 0.3s",
    transform: "translateY(10px)",
    border: "none",
    fontWeight: "bold",
    textShadow: "none !important",
    backgroundColor: "transparent !important"
   // textShadow: "1px 1px 2px rgba(0, 0, 0, 0.8)", 
    //backgroundColor: "rgba(0, 0, 0, 0.5)", 
   // padding: "8px",
    //borderRadius: "4px", // Round the edges of the background
  }}
>
  {image.description}
</Typography>

                <ImageMarked className="MuiImageMarked-root" />
              </Typography>
            </Image>
          </ImageButton>
        ))}
      </Box>
    </div>
  );
}
