// // import React from 'react';
// // import { Line } from 'react-chartjs-2';
// // import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';

// // // Register required components
// // ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

// // export default function TableNo14() {
// //     // Data for the table and chart
// //     const employmentData = [
// //         { label: 'American Indian or Alaska Native Alone', value: 377 },
// //         { label: 'Asian Alone', value: 1090 },
// //         { label: 'Black or African American Alone', value: 1480 },
// //         { label: 'Native Hawaiian or Other Pacific Islander Alone', value: 163 },
// //         { label: 'Two or More Race Groups', value: 945 },
// //         { label: 'White Alone', value: 16063 }
// //     ];

// //     // Line chart data
// //     const chartData = {
// //         labels: employmentData.map(data => data.label),
// //         datasets: [
// //             {
// //                 label: 'Employment Trend (2023-2018)',
// //                 data: employmentData.map(data => data.value),
// //                 borderColor: 'rgba(54, 162, 235, 1)',
// //                 backgroundColor: 'rgba(54, 162, 235, 0.2)',
// //                 pointBackgroundColor: 'rgba(54, 162, 235, 1)',
// //                 pointBorderColor: 'rgba(54, 162, 235, 1)',
// //                 pointRadius: 5,
// //                 fill: true,
// //                 tension: 0.3, // Adds curve to the line
// //             }
// //         ]
// //     };

// //     const chartOptions = {
// //         responsive: true,
// //         maintainAspectRatio: false, // Allows the chart to resize properly
// //         plugins: {
// //             legend: {
// //                 position: 'top',
// //             },
// //             title: {
// //                 display: true,
// //                 text: 'Employment Trend by Race (2023-2018)',
// //             },
// //         },
// //         scales: {
// //             y: {
// //                 beginAtZero: true,
// //                 title: {
// //                     display: true,
// //                     text: 'Employment Count'
// //                 }
// //             },
// //             x: {
// //                 title: {
// //                     display: true,
// //                     text: 'Race Label'
// //                 }
// //             }
// //         }
// //     };

// //     return (
// //         <div className="employment-trend-container">
// //             {/* Table */}
// //             <table className="table-auto border-collapse w-full my-6 text-left">
// //                 <thead>
// //                     <tr>
// //                         <th className="border px-4 py-2">Race Label</th>
// //                         <th className="border px-4 py-2">Employment Trend (2023-2018)</th>
// //                     </tr>
// //                 </thead>
// //                 <tbody>
// //                     {employmentData.map((item, index) => (
// //                         <tr key={index}>
// //                             <td className="border px-4 py-2">{item.label}</td>
// //                             <td className="border px-4 py-2">{item.value}</td>
// //                         </tr>
// //                     ))}
// //                 </tbody>
// //             </table>

// //             {/* Responsive Chart Container */}
// //             <div className="chart-container w-full md:w-3/4 lg:w-1/2 mx-auto my-6" style={{ position: 'relative', height: '60vh', width: '100%' }}>
// //                 <Line data={chartData} options={chartOptions} />
// //             </div>
// //         </div>
// //     );
// // }

// import React from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';

// // Register required components
// ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

// export default function TableNo14() {
//   // Data for the table and chart
//   const employmentData = [
//     { label: 'American Indian or Alaska Native Alone', value: 377 },
//     { label: 'Asian Alone', value: 1090 },
//     { label: 'Black or African American Alone', value: 1480 },
//     { label: 'Native Hawaiian or Other Pacific Islander Alone', value: 163 },
//     { label: 'Two or More Race Groups', value: 945 },
//     { label: 'White Alone', value: 16063 },
//   ];

//   // Line chart data
//   const chartData = {
//     labels: employmentData.map((data) => data.label),
//     datasets: [
//       {
//         label: 'Employment Trend (2023-2018)',
//         data: employmentData.map((data) => data.value),
//         borderColor: 'rgba(54, 162, 235, 1)',
//         backgroundColor: 'rgba(54, 162, 235, 0.2)',
//         pointBackgroundColor: 'rgba(54, 162, 235, 1)',
//         pointBorderColor: 'rgba(54, 162, 235, 1)',
//         pointRadius: 5,
//         fill: true,
//         tension: 0.3, // Adds curve to the line
//       },
//     ],
//   };

//   const chartOptions = {
//     responsive: true,
//     maintainAspectRatio: false, // Allows the chart to resize properly
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//       title: {
//         display: true,
//         text: 'Employment Trend by Race (2023-2018)',
//       },
//     },
//     scales: {
//       y: {
//         beginAtZero: true,
//         title: {
//           display: true,
//           text: 'Employment Count',
//         },
//       },
//       x: {
//         title: {
//           display: true,
//           text: 'Race Label',
//         },
//         ticks: {
//           autoSkip: true,
//           maxRotation: 0,
//           minRotation: 0,
//         },
//       },
//     },
//   };

//   return (
//     <div className="employment-trend-container">
//       {/* Table */}
//       <table className="table-auto border-collapse w-full my-6 text-left">
//         <thead>
//           <tr>
//             <th className="border px-4 py-2">Race Label</th>
//             <th className="border px-4 py-2">Employment Trend (2023-2018)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {employmentData.map((item, index) => (
//             <tr key={index}>
//               <td className="border px-4 py-2">{item.label}</td>
//               <td className="border px-4 py-2">{item.value}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Responsive Chart Container */}
//       <div className="overflow-x-auto">
//         <div
//           className="chart-container w-full md:w-3/4 lg:w-1/2 mx-auto my-6"
//           style={{
//             position: 'relative',
//             height: '60vh',
//             minWidth: '500px', // Ensure minimum width for better view on mobile
//           }}
//         >
//           <Line data={chartData} options={chartOptions} />
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

// Register required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export default function TableNo14() {
  const pdfRef = useRef();

  // Data for the table and chart
  const employmentData = [
    { label: "American Indian or Alaska Native Alone", value: 377 },
    { label: "Asian Alone", value: 1090 },
    { label: "Black or African American Alone", value: 1480 },
    { label: "Native Hawaiian or Other Pacific Islander Alone", value: 163 },
    { label: "Two or More Race Groups", value: 945 },
    { label: "White Alone", value: 16063 },
  ];

  // Line chart data
  const chartData = {
    labels: employmentData.map((data) => data.label),
    datasets: [
      {
        label: "Employment Trend (2023-2018)",
        data: employmentData.map((data) => data.value),
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        pointBackgroundColor: "rgba(54, 162, 235, 1)",
        pointBorderColor: "rgba(54, 162, 235, 1)",
        pointRadius: 5,
        fill: true,
        tension: 0.3,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Employment Trend by Race (2023-2018)",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Employment Count",
        },
      },
      x: {
        title: {
          display: true,
          text: "Race Label",
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px";

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      // Add content
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      // Add footer
      pdf.setLineWidth(0.5);
      pdf.line(10, 260, 200, 260);
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
      pdf.text("20250106.v1.85", 148, 275);
      pdf.line(10, 35, 200, 35);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

      pdf.save("employment_trend.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="employment-trend-container">
      <div ref={pdfRef}>
        {/* Table */}
        <table className="table-auto border-collapse w-full my-6 text-left">
          <thead>
            <tr>
              <th className="border px-4 py-2">Race Label</th>
              <th className="border px-4 py-2">Employment Trend (2023-2018)</th>
            </tr>
          </thead>
          <tbody>
            {employmentData.map((item, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{item.label}</td>
                <td className="border px-4 py-2">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Responsive Chart */}
        {/* <div
          style={{
            overflowX: "auto", // Enables horizontal scroll
            display: "flex",
            justifyContent: "center",
            width: "100%", // Takes full width
          }}
        >
          <div
            style={{
              minWidth: "600px", // Minimum width to enable scroll
              width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
              height: "400px",
            }}
          > */}

        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
              height: "400px",
            }}
          >
            <Line data={chartData} options={chartOptions} />
          </div>
        </div>
      </div>

      {/* Download Button */}
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
        </button>
      </div>
    </div>
  );
}
