


import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DescriptionIcon from '@mui/icons-material/Description';
import { Link } from 'react-router-dom';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


const logo = "/img/unemployment-logo.png"

export default function UnemploymentRate() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const data = [
    { year: "2014", Jan: 3.8, Feb: 3.7, Mar: 3.7, Apr: 3.6, May: 3.6, Jun: 3.6, Jul: 3.6, Aug: 3.6, Sep: 3.6, Oct: 3.6, Nov: 3.6, Dec: 3.6 },
    { year: "2015", Jan: 3.6, Feb: 3.5, Mar: 3.5, Apr: 3.5, May: 3.5, Jun: 3.5, Jul: 3.5, Aug: 3.5, Sep: 3.5, Oct: 3.5, Nov: 3.5, Dec: 3.5 },
    { year: "2016", Jan: 3.5, Feb: 3.5, Mar: 3.5, Apr: 3.4, May: 3.4, Jun: 3.4, Jul: 3.3, Aug: 3.3, Sep: 3.3, Oct: 3.2, Nov: 3.2, Dec: 3.2 },
    { year: "2017", Jan: 3.2, Feb: 3.2, Mar: 3.2, Apr: 3.2, May: 3.2, Jun: 3.2, Jul: 3.2, Aug: 3.2, Sep: 3.1, Oct: 3.1, Nov: 3.1, Dec: 3.1 },
    { year: "2018", Jan: 3.0, Feb: 3.0, Mar: 3.0, Apr: 2.9, May: 2.9, Jun: 2.9, Jul: 2.9, Aug: 2.9, Sep: 2.9, Oct: 2.9, Nov: 2.9, Dec: 2.8 },
    { year: "2019", Jan: 2.8, Feb: 2.7, Mar: 2.7, Apr: 2.6, May: 2.6, Jun: 2.5, Jul: 2.5, Aug: 2.5, Sep: 2.4, Oct: 2.4, Nov: 2.4, Dec: 2.4 },
    { year: "2020", Jan: 2.5, Feb: 2.5, Mar: 2.5, Apr: 10.0, May: 7.7, Jun: 6.4, Jul: 5.7, Aug: 4.9, Sep: 4.5, Oct: 4.0, Nov: 3.8, Dec: 3.6 },
    { year: "2021", Jan: 3.4, Feb: 3.2, Mar: 3.1, Apr: 3.0, May: 2.9, Jun: 2.8, Jul: 2.7, Aug: 2.6, Sep: 2.5, Oct: 2.4, Nov: 2.3, Dec: 2.2 },
    { year: "2022", Jan: 2.2, Feb: 2.2, Mar: 2.2, Apr: 2.3, May: 2.3, Jun: 2.3, Jul: 2.4, Aug: 2.4, Sep: 2.5, Oct: 2.5, Nov: 2.5, Dec: 2.5 },
    { year: "2023", Jan: 2.5, Feb: 2.5, Mar: 2.5, Apr: 2.5, May: 2.5, Jun: 2.6, Jul: 2.7, Aug: 2.7, Sep: 2.8, Oct: 2.8, Nov: 2.8, Dec: 2.8 },
    { year: "2024", Jan: 2.8, Feb: 2.8, Mar: 2.8, Apr: 2.8, May: 2.9, Jun: 3.0, Jul: 3.2, Aug: 3.3, Sep: 3.5, Oct: 3.5, Nov: 3.5, Dec: null },
  ];
  const series = data.map((entry) => ({
    name: `Year ${entry.year}`,
    data: entry.values,
  }));

  const options = {
    chart: {
      type: 'area',
      height: '100%',
      zoom: { enabled: false },
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
      title: { text: 'Months' },
    },
    yaxis: {
      title: { text: 'Unemployment Rate (%)' },
    },
    title: {
      text: 'Unemployment Rate by Year and Month',
      align: 'center',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.3,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val) => `${val.toFixed(1)}%`,
      },
    },
  };

  const monthlyTrends = Object.keys(data[0]).slice(1).map((month) => {
    const monthData = { name: month };
    data.forEach((yearObj) => {
      monthData[yearObj.year] = yearObj[month];
    });
    return monthData;
  });






return (




<div
  style={{ background: 'rgb(210 234 255)', marginTop: '-1rem', paddingTop: '1rem' }}
  className="flex flex-col items-center flex-grow h-auto sm:h-[100vh]"
>
  <div className="px-4 sm:px-6 lg:px-20 mt-[9rem] lg:mx-[10rem] w-full max-w-7xl">
  <div className="text-3xl sm:text-4xl font-semibold text-center mb-4 px-4">
     <h1> Unemployment Rate in Utah</h1>
      <div className="text-2xl">Unemployment Rate in UT 3.5% (compared to 4.2 National)</div>
    </div>
    <div className="text-2xl sm:text-3xl font-semibold text-center mb-4 px-4">
      In October 2024, the unemployment rate in Utah was lower than the US overall.
    </div>
    <div className="relative">
      <div className="w-full overflow-hidden">
        <ResponsiveContainer width="100%" height={500}>
          <ComposedChart data={monthlyTrends}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {data.map((yearData) => (
              <Line
                key={yearData.year}
                type="monotone"
                dataKey={yearData.year}
                stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                activeDot={{ r: 8 }}
              />
            ))}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className="flex flex-col sm:flex-row justify-between mt-6 gap-4 items-center mb-6">
        <div className="text-sm text-center sm:text-left flex">
          {/* Source: <a href="https://www.bls.gov/eag/eag.ut.htm" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">www.bls.gov</a> */}

          <span className="mr-[1.5rem] mt-[1.5rem]">Source:</span>
          <img src={logo} className='w-[3rem] h-[3rem]'></img>
          <span className='ml-3 mt-3'>U.S. Bureau of Labour Statistics</span>
        </div>
        <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
          <Link to="/utah-at-glance/unemployment-rate/dataset">
            <Button className="!bg-red-700 !border-red-700 hover:!bg-red-900 w-full sm:w-auto">
              DataSet <DescriptionIcon style={{ fontSize: 20 }} />
            </Button>
          </Link>
          <Button variant="primary" onClick={handleShow} className="w-full sm:w-auto">
            This Chart Described <ArrowForwardIosIcon style={{ fontSize: 20 }} />
          </Button>
        </div>
      </div>
    </div>

    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <span className="font-bold">This Chart Described</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="text-justify">
        This chart illustrates the monthly unemployment rate trends from 2014 to 2024. Each line represents a specific year, showing changes in unemployment
        rates over months (January to December). Key highlights include seasonal fluctuations and significant spikes, such as in 2020 due to the economic
        impact of the pandemic. The data helps visualize long-term trends and compare year-to-year variations in unemployment rates.
      </Offcanvas.Body>
    </Offcanvas>
  </div>
</div>

);
}