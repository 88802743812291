import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Table } from "react-bootstrap";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const EngTable1 = (props) => {
  const pdfRef = useRef();
  const { data } = props;

  // Ensure data is valid and not empty
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  const years = data.map((item) => item.year);

  const tableHeaders = [
    "Year",
    "All Sexes",
    "Female",
    "Male",
    "All Sexes Growth (%)",
    "Female Growth (%)",
    "Male Growth (%)",
  ];

  const chartData = {
    labels: years,
    datasets: [
      {
        label: "All Sexes",
        data: data.map((item) => parseInt(item.all_sexes)),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Female",
        data: data.map((item) => parseInt(item.female)),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
      {
        label: "Male",
        data: data.map((item) => parseInt(item.male)),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Employment Growth by Sex (2018-2023)",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Year",
        },
        ticks: {
          maxRotation: 45,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Count",
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;

    const originalWidth = input.style.width;
    input.style.width = "1100px";
    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);

      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString();
      pdf.setFontSize(12);
      pdf.text(`Downloaded on: ${formattedDate}`, 150, 295);

      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(10, 35, 200, 35);

      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      pdf.line(10, 260, 200, 260);

      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);

      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);

      pdf.text("20250106.v1.85", 148, footerStartY + 10);

      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("eng_table1.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="dashboard-container">
      <div ref={pdfRef} className="content-to-export">
        <div style={{ padding: "20px" }}>
          <h2>Employment Growth by Sex (2018-2023)</h2>

          {/* Render Table with Horizontal Scrolling */}
          <div style={{ overflowX: "auto", marginBottom: "20px" }}>
            <Table
              border="1"
              cellPadding="10"
              style={{ width: "100%", minWidth: "600px" }}
            >
              <thead>
                <tr>
                  {tableHeaders.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>{row.year}</td>
                    <td>{row.all_sexes}</td>
                    <td>{row.female}</td>
                    <td>{row.male}</td>
                    <td>{row.all_sexes_growth || "N/A"}</td>
                    <td>{row.female_growth || "N/A"}</td>
                    <td>{row.male_growth || "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Bar Chart Section */}
          <div
            style={{
              width: "90%",
              maxWidth: "800px",
              margin: "auto",
              overflowX: "auto",
            }}
          >
            <div
              style={{
                width: "100%",
                minWidth: "600px",
                height: "400px",
              }}
            >
              <Bar data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <span>
            <img className="w-[2rem]" src={pdf} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default EngTable1;
