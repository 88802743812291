import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // For navigation
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRef } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../styles/Verticals.css";
import "./HomePage.css";
import "swiper/css";
import "swiper/css/pagination";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../styles/HomePage.css"; // Import the CSS file
import "../styles/Timer.css"; 
import "../styles/SocialMediaSection.css";
import SocialMediaEmbed from "./SocialMediaEmbed";

//import * as React from 'react';
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";



import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import CardActionArea from '@mui/material/CardActionArea';

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";

// import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalFunction from "./Modal";
import "../styles/Aerospace.css";
import UtahAtGlance from "./UtahAtGlance";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Navbar1 from "./Navbar";

const image = "/img/utahdata.jpg";
const image1 = "/img/utahdata1.jpg";
const image2 = "/img/utahdata2.jpg";
const kpi = "/img/KPI-DASH 2.png";
const logo = "/img/1.png";
const UtahAtGlanceImage = "/img/Utah-at-glance-image.png";

const olympic0 = "/img/utah-olympic0.avif"
const olympic = "/img/utah-olympic.avif"
const olympic2 = "/img/utah-olympic2.avif"
const olympic3 = "/img/utah-olympic3.avif"
const olympicWebsite = "/img/olympic-website.avif"
const venue1 = "/img/olympic-venue1.avif"
const venue2 = "/img/olympic-venue2.avif"
const venue3 = "/img/olympic-venue3.avif"
const venue4 = "/img/olympic-venue4.avif"
const youtube = "/img/youtube.png"
const x = "/img/x.png"
const instagram = "/img/instagram.jpeg"
const facebook = "/img/facebook.jpg"

export default function UtahOlympic() {








    const socialMediaPosts = [
        {
          platform: "YouTube",
          link: "https://www.youtube.com/@slc-ut2034",
          embed: `<iframe width="100%" height="450" src="https://www.youtube.com/embed/KRn3Ynu7AgY" title="Folympic 2034 YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
          icon: youtube
        },
        {
          platform: "Twitter (X)",
          link: "https://x.com/SLCUT2034",
          embed: `<blockquote class="twitter-tweet"><a href="https://twitter.com/SLCUT2034/status/1816056743752802340"></a></blockquote>`,
          icon: x
        },
        {
          platform: "Instagram",
          link: "https://www.instagram.com/slcut2034/",
          embed: `<blockquote class="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/DAetmkTyOAW/?img_index=1/" data-instgrm-version="14"></blockquote>`,
          icon: instagram
        },
        {
          platform: "Facebook",
          link: "https://www.facebook.com/SLCUT2034",
          embed: `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FSLCUT2034%2Fposts%2F1165952578428448&show_text=true&width=500" width="100%" height="700" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`,
          icon: facebook
        },
      ];


  const [currentTime, setCurrentTime] = useState(dayjs());
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(dayjs());
    }, 1000); // Update every second

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);


  const [timeRemaining, setTimeRemaining] = useState({});
  const [eventStatus, setEventStatus] = useState(""); 


  const startDate = dayjs("2034-02-10T00:00:00"); // Start of Olympics
  const endDate = dayjs("2034-02-26T23:59:59"); // End of Olympics


  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = dayjs();

      if (now.isBefore(startDate)) {
        // Countdown to start
        const diffYears = startDate.diff(now, "year");
        const diffMonths = startDate.diff(now.add(diffYears, "year"), "month");
        const diffDays = startDate.diff(
          now.add(diffYears, "year").add(diffMonths, "month"),
          "day"
        );
        const diffHours = startDate.diff(
          now.add(diffYears, "year")
            .add(diffMonths, "month")
            .add(diffDays, "day"),
          "hour"
        );
        const diffMinutes = startDate.diff(
          now.add(diffYears, "year")
            .add(diffMonths, "month")
            .add(diffDays, "day")
            .add(diffHours, "hour"),
          "minute"
        );
        const diffSeconds = startDate.diff(
          now.add(diffYears, "year")
            .add(diffMonths, "month")
            .add(diffDays, "day")
            .add(diffHours, "hour")
            .add(diffMinutes, "minute"),
          "second"
        );

        setTimeRemaining({
          years: diffYears,
          months: diffMonths,
          days: diffDays,
          hours: diffHours,
          minutes: diffMinutes,
          seconds: diffSeconds,
        });
        setEventStatus("upcoming");
      } else if (now.isBefore(endDate)) {
        // Event is ongoing
        const diffDays = endDate.diff(now, "day");
        const diffHours = endDate.diff(now.add(diffDays, "day"), "hour");
        const diffMinutes = endDate.diff(
          now.add(diffDays, "day").add(diffHours, "hour"),
          "minute"
        );
        const diffSeconds = endDate.diff(
          now.add(diffDays, "day").add(diffHours, "hour").add(diffMinutes, "minute"),
          "second"
        );

        setTimeRemaining({
          days: diffDays,
          hours: diffHours,
          minutes: diffMinutes,
          seconds: diffSeconds,
        });
        setEventStatus("ongoing");
      } else {
        // Event has ended
        setTimeRemaining({});
        setEventStatus("ended");
      }
    };

    calculateTimeRemaining(); // Initial calculation
    const interval = setInterval(calculateTimeRemaining, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup
  }, []);

  return (
    <div>
       <Swiper
          direction="horizontal"
          loop={true}
          pagination={{ clickable: true }}
          className="swiper-container mt-adjustment"
        >

<SwiperSlide style={{ minHeight: "100vh" }}>
            <div className="slide-content " style={{ minHeight: "100vh" }}>
              <img
                src={olympic0}
                alt="Utah Olympic Slide 1"
                className="slide-image !z-0"
                style={{ minHeight: "100vh" }}
              />
             
           
                 

              
             <div className="slide-text-olympic sm:mt-[6.5rem] mt-[4.5rem]">Utah Olympic Winter Games 2034</div>

          <div className="slide-time text-[#FFAA1D]">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div
                  style={{
                    color: "white",
                    fontSize: "30px",
                    lineHeight: "1.6",
                    textAlign: "center",
                    width: "35rem",
                  }}
                >
                  {eventStatus === "upcoming" && (
                    <>
                    


                    <div
  style={{
    textAlign: "center",
    padding: "1rem",
    fontSize: "1.5rem", // Default font size for larger screens
    lineHeight: "1.6",
  }}
  className="responsive-countdown"
>

  <div
  // style={{
  //   display: "flex",
  //   flexWrap: "wrap", // Allow wrapping on smaller screens
  //   gap: "1rem", // Space between boxes
  //   justifyContent: "center", // Center align on all screens
  //   margin: "0 auto", // Center the entire row
  // }}
  style={{
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // 3 boxes per row
    // Space between boxes
    justifyContent: "center", // Center the grid
    // Center the entire grid container
    padding: "0 0.5rem",
    marginLeft:"2rem",
    rowGap:"1rem",
    
  }}
  className="countdown-boxes !mx-[4rem] !ml-[5rem]"
>

  <div className="countdown-box w-7 sm:w-auto  ">
    <div className="countdown-number">{timeRemaining.years}</div>
    <div className="countdown-label">Years</div>
  </div>


  <div className="countdown-box  w-7 sm:w-auto ">
    <div className="countdown-number">{timeRemaining.months}</div>
    <div className="countdown-label">Months</div>
  </div>


  <div className="countdown-box  w-7 sm:w-auto ">
    <div className="countdown-number">{timeRemaining.days}</div>
    <div className="countdown-label">Days</div>
  </div>


  <div className="countdown-box w-7 sm:w-auto  ">
    <div className="countdown-number">{timeRemaining.hours}</div>
    <div className="countdown-label">Hours</div>
  </div>

  
  <div className="countdown-box  w-7 sm:w-auto ">
    <div className="countdown-number">{timeRemaining.minutes}</div>
    <div className="countdown-label">Minutes</div>
  </div>

 
  <div className="countdown-box  w-7 sm:w-auto ">
    <div className="countdown-number">{timeRemaining.seconds}</div>
    <div className="countdown-label">Seconds</div>
  </div>
</div>

</div>


                    </>
                  )}
                  {eventStatus === "ongoing" && (
                    <>
                      <div>The Utah 2034 Olympics are ongoing!</div>
                      <div>
                        {timeRemaining.days} days {timeRemaining.hours} hours{" "}
                        {timeRemaining.minutes} minutes {timeRemaining.seconds}{" "}
                        seconds remaining
                      </div>
                    </>
                  )}
                  {eventStatus === "ended" && (
                    <div>The Utah 2034 Olympics have ended. Thank you for joining!</div>
                  )}
                </div>
                <div
    style={{
      fontSize: "2rem", // Larger text for the date
      fontWeight: "bold",
      marginBottom: "0.5rem",
    }}
    className="date-text"
  >
   <div className="text-1xl font-normal">left for</div> February 10, 2034
  </div>
              </LocalizationProvider>
              </div>
                
                

               
              </div>
            
          </SwiperSlide>
          {/* <SwiperSlide style={{ minHeight: "100vh" }}>
            <div className="slide-content " style={{ minHeight: "100vh" }}>
              <img
                src={olympic}
                alt="Utah Olympic Slide 2"
                className="slide-image !z-0"
                style={{ minHeight: "100vh" }}
              />
             
             <div className="slide-time text-[#FFAA1D]">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div
                  style={{
                    color: "white",
                    fontSize: "30px",
                    lineHeight: "1.6",
                    textAlign: "center",
                    width: "35rem",
                  }}
                >
                  {eventStatus === "upcoming" && (
                    <>
                    


                      <div
  style={{
    textAlign: "center",
    padding: "1rem",
    fontSize: "1.5rem", // Default font size for larger screens
    lineHeight: "1.6",
  }}
  className="responsive-countdown"
>

  <div
  style={{
    display: "flex",
    flexWrap: "wrap", // Allow wrapping on smaller screens
    gap: "1rem", // Space between boxes
    justifyContent: "center", // Center align on all screens
    margin: "0 auto", // Center the entire row
  }}
  className="countdown-boxes"
>
 
  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.years}</div>
    <div className="countdown-label">Years</div>
  </div>

  
  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.months}</div>
    <div className="countdown-label">Months</div>
  </div>

  
  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.days}</div>
    <div className="countdown-label">Days</div>
  </div>

 
  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.hours}</div>
    <div className="countdown-label">Hours</div>
  </div>

  
  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.minutes}</div>
    <div className="countdown-label">Minutes</div>
  </div>


  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.seconds}</div>
    <div className="countdown-label">Seconds</div>
  </div>
</div>

</div>

                    </>
                  )}
                  {eventStatus === "ongoing" && (
                    <>
                      <div>The Utah 2034 Olympics are ongoing!</div>
                      <div>
                        {timeRemaining.days} days {timeRemaining.hours} hours{" "}
                        {timeRemaining.minutes} minutes {timeRemaining.seconds}{" "}
                        seconds remaining
                      </div>
                    </>
                  )}
                  {eventStatus === "ended" && (
                    <div>The Utah 2034 Olympics have ended. Thank you for joining!</div>
                  )}
                </div>

                <div
    style={{
      fontSize: "2rem", // Larger text for the date
      fontWeight: "bold",
      marginBottom: "0.5rem",
    }}
    className="date-text"
  >
   <div className="text-1xl font-normal">left for</div> February 10, 2034
  </div>
              </LocalizationProvider>
              </div>
                <div className="slide-text sm:mt-[9rem] mt-[12rem]">Utah Olympic Winter Games 2034</div>

               
              </div>
            
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-content">
              <img
                src={olympic2}
                alt="Utah Olympic Slide 3"
                className="slide-image"
                style={{ minHeight: "100vh" }}
              />


<div className="slide-time text-[#FFAA1D]">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div
                  style={{
                    color: "white",
                    fontSize: "30px",
                    lineHeight: "1.6",
                    textAlign: "center",
                    width: "35rem",
                  }}
                >
                  {eventStatus === "upcoming" && (
                    <>
                    


                      <div
  style={{
    textAlign: "center",
    padding: "1rem",
    fontSize: "1.5rem", // Default font size for larger screens
    lineHeight: "1.6",
  }}
  className="responsive-countdown"
>

  <div
  style={{
    display: "flex",
    flexWrap: "wrap", // Allow wrapping on smaller screens
    gap: "1rem", // Space between boxes
    justifyContent: "center", // Center align on all screens
    margin: "0 auto", // Center the entire row
  }}
  className="countdown-boxes"
>

  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.years}</div>
    <div className="countdown-label">Years</div>
  </div>

  
  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.months}</div>
    <div className="countdown-label">Months</div>
  </div>

 
  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.days}</div>
    <div className="countdown-label">Days</div>
  </div>


  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.hours}</div>
    <div className="countdown-label">Hours</div>
  </div>

  
  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.minutes}</div>
    <div className="countdown-label">Minutes</div>
  </div>

  
  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.seconds}</div>
    <div className="countdown-label">Seconds</div>
  </div>
</div>

</div>

                    </>
                  )}
                  {eventStatus === "ongoing" && (
                    <>
                      <div>The Utah 2034 Olympics are ongoing!</div>
                      <div>
                        {timeRemaining.days} days {timeRemaining.hours} hours{" "}
                        {timeRemaining.minutes} minutes {timeRemaining.seconds}{" "}
                        seconds remaining
                      </div>
                    </>
                  )}
                  {eventStatus === "ended" && (
                    <div>The Utah 2034 Olympics have ended. Thank you for joining!</div>
                  )}
                </div>
                <div
    style={{
      fontSize: "2rem", // Larger text for the date
      fontWeight: "bold",
      marginBottom: "0.5rem",
    }}
    className="date-text"
  >
   <div className="text-1xl font-normal">left for</div> February 10, 2034
  </div>
              </LocalizationProvider>
              </div>
              <div className="slide-text sm:mt-[9rem] mt-[12rem]">Utah Olympic Winter Games 2034</div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-content">
              <img
                src={olympic3}
                alt="Utah Olympic Slide 4"
                className="slide-image"
                style={{ minHeight: "100vh" }}
              />


<div className="slide-time text-[#FFAA1D]">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div
                  style={{
                    color: "white",
                    fontSize: "30px",
                    lineHeight: "1.6",
                    textAlign: "center",
                    width: "35rem",
                  }}
                >
                  {eventStatus === "upcoming" && (
                    <>
                    


                      <div
  style={{
    textAlign: "center",
    padding: "1rem",
    fontSize: "1.5rem", // Default font size for larger screens
    lineHeight: "1.6",
  }}
  className="responsive-countdown"
>
 
  <div
  style={{
    display: "flex",
    flexWrap: "wrap", // Allow wrapping on smaller screens
    gap: "1rem", // Space between boxes
    justifyContent: "center", // Center align on all screens
    margin: "0 auto", // Center the entire row
  }}
  className="countdown-boxes"
>
  
  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.years}</div>
    <div className="countdown-label">Years</div>
  </div>


  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.months}</div>
    <div className="countdown-label">Months</div>
  </div>


  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.days}</div>
    <div className="countdown-label">Days</div>
  </div>


  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.hours}</div>
    <div className="countdown-label">Hours</div>
  </div>

 
  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.minutes}</div>
    <div className="countdown-label">Minutes</div>
  </div>


  <div className="countdown-box">
    <div className="countdown-number">{timeRemaining.seconds}</div>
    <div className="countdown-label">Seconds</div>
  </div>
</div>

</div>

                    </>
                  )}
                  {eventStatus === "ongoing" && (
                    <>
                      <div>The Utah 2034 Olympics are ongoing!</div>
                      <div>
                        {timeRemaining.days} days {timeRemaining.hours} hours{" "}
                        {timeRemaining.minutes} minutes {timeRemaining.seconds}{" "}
                        seconds remaining
                      </div>
                    </>
                  )}
                  {eventStatus === "ended" && (
                    <div>The Utah 2034 Olympics have ended. Thank you for joining!</div>
                  )}
                </div>
                
                <div
    style={{
      fontSize: "2rem", 
      fontWeight: "bold",
      marginBottom: "0.5rem",
    }}
    className="date-text"
  >
   <div className="text-1xl font-normal">left for</div> February 10, 2034
  </div>
              </LocalizationProvider>
              </div>
              <div className="slide-text sm:mt-[9rem] mt-[12rem]">Utah Olympic Winter Games 2034</div>
            </div>
          </SwiperSlide> */}
        </Swiper>

     






<div className="social-media-section">
  <h2 className="text-center font-bold text-5xl mb-4">
    Follow Utah Olympic Winter Games 2034 on Social Media
  </h2>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
    {socialMediaPosts.map((post, index) => (
      <div
        key={index}
        className="social-card"
      >
        <div className="content">
          <SocialMediaEmbed platform={post.platform} embed={post.embed} />
        </div>
        <div className="social-card-footer">
          <a
            href={post.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            Visit {post.platform} <img src={post.icon} className="h-[2rem] w-auto mx-auto"></img>
          </a>
        </div>
      </div>
    ))}
  </div>
</div>

<div>
    <h2 className="text-5xl font-bold mt-[2rem] text-center flex justify-center mb-[4rem]">Check out Salt-Lake city Utah 2034's official website</h2>
    <div className="justify-center flex text-center mb-[4rem]">
    <a href="https://slc-ut2034.org/" className="no-underline " target="_blank" rel="noopener noreferrer">
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={olympicWebsite}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Salt Lake City Utah 2034
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Salt Lake City proudly welcomes the world to the Utah Olympic 2034 Games, celebrating excellence, unity, and innovation.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </a>
    </div>
</div>

<div>
<h2 className="text-5xl font-bold mt-[2rem] flex justify-center text-center mb-[4rem]">SALT LAKE CITY-UTAH 2034 VENUES</h2>
<div className="flex flex-wrap gap-4 justify-center mb-5">
  <img 
    src={venue1} 
    className="w-[75%] md:w-[48%] lg:w-[48%] h-auto" 
    alt="Venue 1" 
  />
  <img 
    src={venue2} 
    className="w-[75%] md:w-[48%] lg:w-[48%] h-auto" 
    alt="Venue 2" 
  />
  <img 
    src={venue3} 
    className="w-[75%] md:w-[48%] lg:w-[48%] h-auto" 
    alt="Venue 3" 
  />
  <img 
    src={venue4} 
    className="w-[75%] md:w-[48%] lg:w-[48%] h-auto" 
    alt="Venue 4" 
  />
</div>

</div>

    </div>
  )
}
